export default {
    setResults(state, data) {
        state.results = data;
    },
    setPaginationPage(state, page){
        state.pagination.page = page;
    },
    setPaginationTotal(state, total){
        state.pagination.total = total;
    },
    setPaginationMaxPage(state, maxPage){
        state.pagination.maxPage = maxPage;
    },
    setResultsDisplayed(state, subResults){
        state.displayedResults = subResults;
    },
    setAllowCourseSwitching(state, allow){
        state.allowCourseSwitching = allow
    },

};
