import {Graphics, Container} from 'pixi.js'


export class Teleport extends Container {
    arrowSize = 18

    constructor(teleportData, callback) {
        super()
        console.log(teleportData)
        this.data = teleportData

        this.gridCoords = {x: teleportData.x_pos, y: teleportData.y_pos}
        // placeholder
        this.graphics = new Graphics()
        this.graphics.beginFill(0x55FF55, .5);
        this.graphics.drawRect(0, 0, this.data.width * 128, this.data.height * 128);
        this.graphics.endFill();
        this.graphics.pivot.set(-64)
        this.graphics.interactive = true
        this.graphics.buttonMode = true
        this.graphics.addListener('pointerup', () => callback(
            teleportData.destination.episode,
            teleportData.destination.index
        ))

        this.addChild(this.graphics)

        this.upperLayer = new PIXI.Container()
        this.setupNametag()
    }

    setupNametag() {
        const style = new PIXI.TextStyle({
            fontSize: 28,
            //fontWeight: 'bold',
            fill: '#ffffff'
        });

        let tag = this.data.label
        const name = new PIXI.Text(tag, style);
        // const name = new PIXI.Text(this.data.tag_label, style);

        let triangle = new Graphics()
        triangle.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        triangle.beginFill(0xFFFFFF, 1);
        triangle.drawStar(0, 0, 3, this.arrowSize, this.arrowSize / 2, Math.PI);
        triangle.endFill();
        triangle.position.set(this.width / 2, 0) //todo adapt to character sprite size

        let nametag = new Graphics()
        nametag.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        nametag.beginFill(0x4D70F2, .8);
        nametag.drawRect(0, 0, name.width + 35 * 2, name.height + 10 * 2);
        nametag.endFill();
        nametag.pivot.x = nametag.width / 2;
        nametag.position.set(this.width / 2, -triangle.height - nametag.height)


        name.pivot.set(name.width / 2, name.height / 2)
        name.position.set(nametag.width / 2, nametag.height / 2)

        this.upperLayer.addChild(nametag)
        this.upperLayer.addChild(triangle)
        nametag.addChild(name)
    }

}