<template>
  <div class="template-wrapper">
    <template v-if="question.illustration">
        <CheckboxQuestion :question="question" v-if="question.multiple_answer_allowed === true" />
        <McqImgQuestion :question="question" v-else />
    </template>
    <template v-else>
      <CheckboxQuestion :question="question" v-if="question.multiple_answer_allowed === true && !question.display_truefalse" />
      <TrueFalseQuestion :question="question"  v-else-if="question.display_truefalse === true" />
      <McqQuestion :question="question" v-else />
    </template>
  </div>
</template>

<script>
import McqImgQuestion from "./McqImgQuestion.vue";
import McqQuestion from "./McqQuestion.vue";
import CheckboxQuestion from "./CheckboxQuestion.vue";
import TrueFalseQuestion from "./TrueFalseQuestion.vue";

export default {
  name: "McqHandler",
  props: ['question'],
  components: {TrueFalseQuestion, CheckboxQuestion, McqQuestion, McqImgQuestion}
}
</script>

