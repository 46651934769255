import axios from "axios";

export default {
    getQuestions(context) {
        return new Promise((resolve, reject) => {
            try {
                axios.get('/quiz/api/questions/').then((response) => {
                    context.commit("setQuestions", response.data);
                    resolve(response.data);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    getQuestionById(context, id) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/quiz/api/questions/${id}/`).then((response) => {
                    if(response.data.type === 'select'){
                        response.data.type = "select-question"
                    }

                    context.commit("setCurrentQuestion", response.data);
                    resolve(response.data);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    postQuestionAnswer(context, data) {
        return new Promise((resolve, reject) => {
            try {
                console.log("answer sent to backend:", data)
                axios.post(`/quiz/validate/`, data).then((response) => {
                    console.log(response.data)
                    resolve(response.data);
                })
            } catch (error) {
                reject(error);
            }
        });
    },
};
