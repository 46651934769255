import {sound} from "@pixi/sound";
import * as PIXI from 'pixi.js'
import {SoundAssets} from "@/application/constants";

export let SoundManager = {

    mainTheme: null,
    //mainThemePlaying: false,
    ambiant: null,

    playSound(soundName) {
        if (soundName === 'snd_click')
            sound.play('snd_click');
        else {
            if (PIXI.Loader.shared.resources[soundName] !== undefined
                && PIXI.Loader.shared.resources[soundName].sound !== undefined) {
                PIXI.Loader.shared.resources[soundName].sound.play()

            } else {
                if (SoundAssets.hasOwnProperty(soundName)) {
                    console.log(`sound ${soundName} not loaded yet`)
                } else {
                    console.log('unknown sound', soundName)
                }
            }
        }
    },

    playMainTheme() {
        if (this.mainTheme === null) {
            this.mainTheme = sound.add('main_theme', {
                url: '/static/vendors/assets/sounds/music.{mp3,ogg}',
                loop: true,
                volume: .15
            })
        }
        if (!this.mainTheme.isPlaying)
            this.mainTheme.play()
    },
    stopMainTheme() {
        if (this.mainTheme !== null)
            this.mainTheme.stop()
    },


    setAmbiance(map) {
        this.stopAmbiance()
        if (map.name.includes('mars')) {
            console.log('playing mars ambiance')
            PIXI.Loader.shared.resources['ambiant_mars'].sound.loop = true
            this.ambiant = PIXI.Loader.shared.resources['ambiant_mars'].sound
            this.ambiant.play()
        } else {
            console.log('playing spaceship ambiance')
            PIXI.Loader.shared.resources['ambiant_spaceship'].sound.loop = true
            this.ambiant = PIXI.Loader.shared.resources['ambiant_spaceship'].sound
            this.ambiant.play()
        }
    },


    pauseAmbiance() {
        if (this.ambiant !== null && this.ambiant.isPlaying)
            this.ambiant.pause()
    },

    resumeAmbiance() {
        if (this.ambiant !== !this.ambiant.isPlaying)
            this.ambiant.play()
    },

    stopAmbiance() {
        if (this.ambiant !== null)
            this.ambiant.stop()
    },
}