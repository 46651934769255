<template>

  <div class="template-wrapper">
      <div class="inner-content">
    <div class="wrapper question-template matching-question-container ">
      <div class="screen-subtitle mx-auto" v-html="question.statement">
      </div>


      <div class="d-flex row mt-lg-4 question-wrapper ">
        <button class="scroll-buttons arrow small up inactive d-none">
          <arrow-svg/>
        </button>
        <div class="">
          <div class=" pt-1 flex-nowrap d-flex scroll-question ">
            <div class="col-6 col-lg-6 px-lg-3 px-2 justify-content-center">
              <form class="d-flex flex-column position-relative question-container drop-zone w-100 h-100">
                <div
                    v-for="(answer, index) in answers"
                    :key="answer.id"
                    class="question question-group drag-el"
                    draggable
                    @drop="onDrop($event, index)"
                    @dragover.prevent
                    @dragenter.prevent
                    @dragstart="startDrag($event, index)">
                  {{ answer.text }}
                </div>
              </form>
            </div>
            <div class="col-6 col-lg-6 px-lg-3 px-2 justify-content-center">
              <form class="d-flex flex-column position-relative question-container ">
                <div v-for="answer in question.formatted_answers.right" :key="answer.id"
                     class="question unmovable question-group">
                  {{ answer.text }}
                </div>
              </form>
            </div>


          </div>

            <div class="beveledbutton-wrapper submit-btn question-group button w-25 mx-auto">
                <button @click.prevent="doValidate"> Valider</button>
            </div>

        </div>
        <button class="scroll-buttons arrow small down d-none inactive">
          <arrow-svg/>
        </button>
      </div>
    </div>
  </div>
  </div>


</template>

<style scoped>
.drop-zone {
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}

#web-interface .main .wrapper .question-group:not(.button, .unmovable){
    background-color: #0f518b;
    border: solid #167ed0 1px;
    box-shadow: 2px 2px 7px 4px #0a1233b5;
}
#web-interface .main .wrapper .question-group.unmovable{
        background-color: #0b1026;
}

</style>

<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "MatchingQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  data() {
    return {
      answers:null
    }
  },
  created() {
    this.answers = this.question.formatted_answers.left
        .map((a) => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)
  },
  methods: {
    onDrop(evt, indexDest) {
      const indexSource = evt.dataTransfer.getData('index')

      var item = this.answers[indexSource];

      this.answers.splice(indexSource, 1)
      this.answers.splice(indexDest, 0, item)
    },
    startDrag: (evt, index) => {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('index', index)
    },
    doValidate:function(){
      this.selectedAnswer = this.answers.map(item => item.id)
      this.validate();
    }

  }
};
</script>
