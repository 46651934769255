<template>
  <GameScreen>

    <div class="screen-title">
      Épisodes
      <span></span>
    </div>
    <button id="close_button" class="button round">
      <span class="innerborder" @click.prevent="backToMenu">X</span>
      <span class="circle "></span>
    </button>
    <div id="episodes" class="inner-content full sky-background">
      <div class="wrapper align-items-center justify-content-between ">

        <div class="screen-subtitle mx-auto">
          Choisis l'épisode en fonction de la matière
        </div>
        <div
            class="d-flex justify-content-center h-100 align-content-around flex-wrap px-lg-5 pb-1 pb-lg-3 pt-2">
          <div class="episode" @click="setEpisode(1)">
            <div class="img-wrapper"><img src="/static/vendors/images/episode-01.jpg"></div>
            <div class="d-flex flex-wrap">
              <div class="title stylise-font ">En route vers mars</div>
              <div class="w-100">Physique</div>
            </div>
          </div>

          <div class="episode" @click="setEpisode(2)">
            <div class="img-wrapper"><img src="/static/vendors/images/episode-02.jpg"></div>
            <div class="d-flex flex-wrap">
              <div class="title stylise-font">On a marché sur mars</div>
              <div class="w-100">Chimie</div>
            </div>
          </div>

          <div class="episode" @click="setEpisode(3)">
            <div class="img-wrapper"><img src="/static/vendors/images/episode-03.jpg"></div>
            <div class="d-flex  flex-wrap">
              <div class="title stylise-font">Alerte au Blob</div>
              <div class="w-100">Biologie</div>
            </div>
          </div>
          <div v-if="showTest" class="episode" @click="setEpisode(4)">
            <div class="img-wrapper"><img src="/static/vendors/images/episode-03.jpg"></div>
            <div>
              <div class="title">test</div>
              <div>test</div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </GameScreen>
</template>
<style lang="scss">
$BiseauTopLeftBottomRightSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 80%, 95% 100%, 20% 100%, 0 100%, 0 15%);
$BiseauTopLefSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);
$light-blue: #88eeff;

#episodes {
  .wrapper {
    padding: 10px calc(10% + 5px) 10px;

    > div {
      width: 100%;
    }
  }

  .episode {
    width: calc((100% / 3) - 20px);
    color: white;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    cursor: pointer;
    flex-direction: column;

    &:not(:last-child) {
      margin-right: 20px;
    }

    .img-wrapper {
      clip-path: $BiseauTopLefSmall;
      background: white;
      z-index: 2;
    }

    img {
      height: calc(100% - 10px);
      width: calc(100% - 10px);
      top: 5px;
      left: 5px;
      object-fit: cover;
      padding: 0;
      clip-path: $BiseauTopLefSmall;
    }

    > div:nth-child(2) {
      background: rgb(11, 13, 56);
      border: $light-blue 2px solid;
      border-top: 0;
      padding: 10px;
      align-content: start;
      flex-grow: 1;

      * {
        z-index: 2;
        font-size: 1rem;
        margin-top: -5px;
        margin-bottom: auto;
      }

      .title {
        color: $light-blue;
        margin-bottom: 0;
        line-height: 18px;
      }
    }
  }


  @media screen and (min-width: 992px) {
    .wrapper {
      padding: 30px calc(10% + 11px) 10px;

      > div {
        padding-bottom: 1rem;
      }
    }
    .episode {
      left: 25px;
      min-height: calc((100% / 3) - 60px);
      width: 100%;
      flex-direction: row;

      &:not(:last-child) {
        margin-right: unset;
      }

      .img-wrapper {
        clip-path: $BiseauTopLeftBottomRightSmall;
      }

      img {
        clip-path: $BiseauTopLeftBottomRightSmall;
      }


      > div:nth-child(2) {
        background: $light-blue;
        clip-path: $BiseauTopLeftBottomRightSmall;
        width: 70%;
        height: 100%;
        left: -50px;
        margin-left: 0;
        align-content: center;
        padding: 10px 20px 10px 65px;

        * {
          font-size: 1.5rem;
        }

        .title {
          font-size: 2.3rem;
          line-height: 45px;
        }

        &:after {
          width: calc(100% - 2px);
          height: calc(100% - 2px);
          position: absolute;
          clip-path: $BiseauTopLeftBottomRightSmall;
          top: 2px;
          left: 2px;
          background: rgb(11, 13, 56);
          content: "";
          z-index: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1780px) {
  #episodes .wrapper > div {
    width: 75%;
  }
}
</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapMutations} from "vuex";
import {Screens} from "~/application/constants";
import {SoundManager} from "@/application/sounds";
import {GetVideo} from "~/application/helpers";

export default {
  name: "EpisodeScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      showTest: false
    }
  },
  created() {

    //todo remove this when not needed anymore
    let params = new URLSearchParams(window.location.search.substring(1));
    let showTestParam = params.get("show-test")

    if (showTestParam) {
      this.showTest = true
    }
  },
  methods: {
    ...mapMutations(['setActiveScreen']),
    ...mapActions(["playVideo"]),
    setEpisode: function (id) {
      SoundManager.playSound('snd_ep_choice')
      Game.loadEpisode(id, 0, true)
      this.playVideo({video: GetVideo(id, 'intro'), next: Screens.GAME_MAP})
    },
    backToMenu() {
      this.setActiveScreen(Screens.TITLE)
    },
  }
}
</script>