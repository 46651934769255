<template>
  <GameScreen>
    <button id="close_button" class="button round">
      <span class="innerborder" @click.prevent="showHelp">?</span>
      <span class="circle "></span>
    </button>
    <div id="titlescreen" class="inner-content full no-title ">
      <div class="wrapper align-items-center justify-content-between">
        <img class="logo" src="/static/vendors/images/logo.png">
        <img class="title" src="/static/vendors/images/title-img.png">
        <div class="mx-auto mt-2 mt-lg-0 screen-subtitle">Joue et teste tes connaissances en Chimie, Biologie et
          Physique
        </div>
        <div class="d-flex flex-lg-column h-100 pb-lg-4 mt-lg-2 mb-lg-4 button-wrapper flex-wrap">
          <div v-if="!isConnected" class="beveledbutton-wrapper submit-btn  mx-auto">
            <button @click.prevent="startGame">Jouer</button>
          </div>
          <div v-if="isConnected && hasProgress" class="beveledbutton-wrapper submit-btn  mx-auto">
            <button @click.prevent="startGame">Continuer</button>
          </div>
          <div v-if="isConnected && !hasProgress" class="beveledbutton-wrapper submit-btn  mx-auto">
            <button @click.prevent="startGame">Commencer la partie</button>
          </div>
          <div class="beveledbutton-wrapper submit-btn mx-auto">
            <button @click.prevent="showReference" type="submit">Références</button>
          </div>
          <div class="beveledbutton-wrapper submit-btn   mx-auto">
            <button @click.prevent="showResults" type="submit">Diagnostic</button>
          </div>
          <div v-if="isConnected && hasProgress" class="beveledbutton-wrapper submit-btn cancel-button  mx-auto">
            <button @click.prevent="resetGame">Réinitialiser</button>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:character>
      <div id="advice-holder" class="d-flex flex-column position-absolute" @click="toogleCharacter"
           :class="{ hidden:!showCharacter}">
        <div class="bubble-info ">
          <p> !</p>
        </div>
        <div class="bubble-text bubble-text-title">
          <span> x</span>
          <p>Ton diagnostic se met à jour au fil de ta progression.<br>
            N'hésite pas à le consulter.</p>
        </div>
        <img :src="characterImg" alt="" class="character-mini">
      </div>
    </template>
  </GameScreen>
</template>
<style lang="scss" scoped>
#web-interface #advice-holder.hidden .bubble-text {
  opacity: 0;
  display: none;
}

#web-interface #advice-holder .bubble-text {
  height: auto;
  margin: auto 0 10px auto;
  padding: 15px 10px;
  left: -15px;

  &:before {
    right: 55px;
  }
}

#web-interface #advice-holder .bubble-info {
  border: solid 2px #88eeff;
  display: none;
  box-shadow: 0 2px 10px #88eeff;
  border-radius: 15px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
  justify-content: center;
  align-items: center;
  background-color: #1a3474c2;

  p {
    color: #88eeff;
    font-size: 25px;
  }
}

#web-interface #advice-holder.hidden .bubble-info {
  display: flex;
}

#web-interface #advice-holder {
  right: 10px;

  img {
    display: block;
  }
}

#web-interface #titlescreen .beveledbutton-wrapper.cancel-button {
  max-width: 60%;
  white-space: nowrap;
}

@media screen and (min-width: 992px) {
  #web-interface #advice-holder.hidden, #web-interface #advice-holder {
    right: 15px;
  }
  #web-interface #advice-holder.bubble-info, #web-interface #advice-holder.hidden .bubble-info {
    margin: 5px 55px 10px auto;
  }
  #web-interface #advice-holder .bubble-text, #web-interface #advice-holder.hidden .bubble-text {
    margin: 0 0 15px 0;
    opacity: 1;
    padding: 20px 15px;
  }
  #web-interface #titlescreen .beveledbutton-wrapper.cancel-button {
    width: unset;
    height: 50px;
  }
}

@media screen and (min-width: 1140px) {
  #web-interface #advice-holder .bubble-info, #web-interface #advice-holder.hidden .bubble-info {
    display: none;
  }

  #web-interface #advice-holder .bubble-text, #web-interface #advice-holder.hidden .bubble-text {
    display: block;

    span {
      display: none;
    }
  }

  #web-interface #advice-holder.hidden {
    right: 15px;
    width: 250px;
  }
  .bubble-text-title {
    display: block;
    height: 130px !important;
    width: 100% !important;
  }
}

#web-interface #titlescreen.full {
  padding: 25px 10px 10px;

  .wrapper {
    padding: 10px 0;
  }

  .logo {
    max-height: 20px;
  }

  .title {
    max-height: 100px;
  }

  .button-wrapper {
    width: 85%;
  }

  .screen-subtitle {
    background: transparent !important;
    text-shadow: 0 0 4px white;
    margin: 0 auto auto;
    padding-top: 0.5rem;
    width: 60%;
  }

  img {
    padding-bottom: 0;
  }

  @media screen and (min-width: 992px) {
    padding: inherit;
    .wrapper {
      padding: 30px calc(10% + 11px) 10px;
    }
    .button-wrapper {
      width: 75%;
    }
    .logo {
      max-height: 40px;
    }

    .title {
      height: 30vh;
      max-height: 150px;
    }
    .bubble-text-title {
      height: 130px;
      width: 100%;
    }
    .screen-subtitle {
      width: 100%;
    }
  }
}
</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {Screens} from "~/application/constants";
import {SoundManager} from "@/application/sounds";

export default {
  name: "TitleScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      showCharacter: false,
      visitedQuestionsLoaded: false,
    }
  },
  created() {
    this.checkAuthentication().then(() => {
      Promise.all([this.getVisitedQuestions(), this.getAchievements()]).then(() => {
        this.visitedQuestionsLoaded = true
      })
    }).catch((error) => {
      console.log(error)
      this.visitedQuestionsLoaded = true
    })
    SoundManager.stopAmbiance()
    SoundManager.playMainTheme()
    this.setCharacterMini('plock')
  },
  computed: {
    ...mapState('account', ['isConnected']),
    ...mapState('question', ['questionDone']),
    ...mapState(['characterMini', 'selectedAvatarIndex', 'visitedQuestions']),
    ...mapGetters('achievements', ['wonCount']),
    characterImg() {
      return `/static/vendors/images/npc/${this.characterMini.identifier}.png`
    },
    hasProgress() {
      return this.wonCount !== 0 || this.visitedQuestions.length !== 0 || this.questionDone.length !== 0
    }
  },
  methods: {
    ...mapMutations(['setActiveScreen', 'setLastScreen', 'setActiveCourse', 'setCharacterMini']),
    ...mapMutations("results", ['setAllowCourseSwitching']),
    ...mapActions("account", ['checkAuthentication']),
    ...mapActions("achievements", ['getAchievements']),
    ...mapActions(['getVisitedQuestions']),
    startGame() {
      SoundManager.playSound('snd_click')
      if (this.isConnected) {
        //present avatar selection if store still has negative index for state 'selectedAvatarIndex'
        if (this.selectedAvatarIndex === -1)
          this.setActiveScreen(Screens.AVATAR_SELECT)
        else
          this.setActiveScreen(Screens.EPISODES_LIST)
        this.setAllowCourseSwitching(false)
      } else {
        this.setActiveScreen(Screens.LOGIN)
      }
    },
    showHelp(){
      this.setLastScreen(Screens.TITLE)
      this.setActiveScreen(Screens.HELP)
    },
    showReference() {
      SoundManager.playSound('snd_click')
      if (this.isConnected) {
        this.setActiveScreen(Screens.REFERENCES)
      } else {
        this.setActiveScreen(Screens.LOGIN)
      }
    },
    showResults() {
      SoundManager.playSound('snd_click')
      if (this.isConnected) {
        this.setActiveScreen(Screens.RESULTS)
        this.setActiveCourse(0)
        this.setAllowCourseSwitching(true)
      } else {
        this.setActiveScreen(Screens.LOGIN)
      }
    },
    toogleCharacter() {
      this.showCharacter = !this.showCharacter;
    },
    resetGame() {
      SoundManager.playSound('snd_click')
      this.$emit('erase-progress')
    }
  }
}
</script>
