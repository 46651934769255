<template>
  <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
       viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/>
</svg>
</template>
<script>
export default {
  name: 'arrow-svg'
}
</script>