import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import modules from "./modules";
import {Screens} from "~/application/constants";

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        activeScreen: Screens.TITLE,
        episodes: null,
        visitedQuestions: [],
        dialogue: null,
        feedback_type: false,
        character: {identifier: null, name: null},
        characterMini: {identifier: null},
        map: null,
        interaction: null,
        dataLoaded: false,
        referenceToGuess: null,
        referenceInteractive: false,
        activeCourse: 1,
        nextScreen: null,
        videoToPlay: null,
        selectedAvatarIndex: -1,
        lastScreen: Screens.TITLE,
    },
    getters: {
        getEpisode: state => id => {
            return state.episodes.find(episode => episode.id === id)
        },
        getEpisodeMapIndex: (state, getters) => (episodeId, mapIndex) => {
            return getters.getEpisode(episodeId).maps.find(map => map.index === mapIndex)
        },
        getEpisodeFirstMap: (state, getters) => id => {
            return getters.getEpisodeMapIndex(id, 0)
        },
        getEpisodeQuestionCount: (state, getters) => id => {
            return getters.getEpisode(id).question_count
        },
        getEpisodeMaxScore: (state, getters) => id => {
            return getters.getEpisode(id).max_score
        },
        getEpisodeCurrentScore: (state, getters) => id => {
            return getters.getEpisode(id).current_score
        },
    },
    mutations: {
        initialiseStore(state) {
            let csrfToken = localStorage.getItem('csrfToken');
            let isConnected = localStorage.getItem('isConnected');
            /* let avatar = localStorage.getItem('avatarIndex');

             if (avatar) {
                 state.selectedAvatarIndex = parseInt(avatar)
             }*/
            if (csrfToken) {
                state.account.hasCsrf = true
            }

            if (isConnected) {
                //TODO: handle expired session
                state.account.isConnected = true
            }
        },
        setActiveScreen(state, screen) {
            state.activeScreen = screen
        },
        setLastScreen(state, screen){
            state.lastScreen = screen
        },
        setDialogue(state, payload) {
            state.dialogue = payload.data.question[payload.type]
            state.feedback_type = payload.type
            state.character.identifier = payload.data.character.identifier
            state.character.name = payload.data.character.name
            state.activeScreen = Screens.CHARACTER_SPEAKING
        },
        setCharacterMini(state, identifier) {
            state.characterMini.identifier = identifier
        },
        setReferenceToGuess(state, refId) {
            state.referenceToGuess = refId
            state.referenceInteractive = true
            state.activeScreen = Screens.REFERENCES
        },
        clearReferenceToGuess(state) {
            state.referenceToGuess = null
            state.referenceInteractive = false
        },
        setEpisodes(state, episodes) {
            state.episodes = episodes
        },
        setVisitedQuestions(state, data) {
            state.visitedQuestions = data.visited_questions
        },
        setNextInteractionId(state, id) {
            state.nextInteractionId = id
        },
        setMap(state, data) {
            state.map = data
        },
        setInteraction(state, data) {
            state.interaction = data
        },
        setDataLoaded(state, loaded) {
            state.dataLoaded = loaded
        },
        setActiveCourse(state, episodeId) {
            state.activeCourse = episodeId;
        },
        setNextScreen(state, screen) {
            state.nextScreen = screen;
        },
        setVideoToPlay(state, videoUrl) {
            state.videoToPlay = videoUrl
        },
        setSelectedAvatarIndex(state, index) {
            state.selectedAvatarIndex = index
            //localStorage.setItem('avatarIndex', JSON.stringify(state.selectedAvatarIndex));
        }
    },
    actions: {
        getEpisodes(context) {
            return new Promise((resolve, reject) => {
                try {
                    axios.get('/api/episode/').then((response) => {
                        context.commit("setEpisodes", response.data);
                        resolve(response.data);
                    })

                } catch (error) {
                    reject(error);
                }
            });
        },
        getVisitedQuestions(context) {
            return new Promise((resolve, reject) => {
                try {
                    axios.get('/api/visited_questions/').then((response) => {
                        context.commit("setVisitedQuestions", response.data);
                        resolve(response.data);
                    })

                } catch (error) {
                    reject(error);
                }
            });
        },
        eraseProgress() {
            return new Promise((resolve, reject) => {
                try {
                    axios.get('/api/erase_progress/').then((response) => {
                        resolve(response.data);
                    })

                } catch (error) {
                    reject(error);
                }
            });
        },
        setCourse({state, commit, dispatch}, courseId) {
            return new Promise((resolve, reject) => {
                if (courseId < 0 || courseId >= state.results.courseName.length) {
                    reject({message: 'course Id out of bound'});
                }
                commit("setActiveCourse", courseId);
                if(courseId === 0)
                    resolve()
                else
                    return dispatch("results/getResultsForEpisode", courseId).then(() => resolve())

                //resolve();
            });
        },
        playVideo({state, commit}, obj) {
            SoundManager.pauseAmbiance()
            commit('setVideoToPlay', obj.video)
            commit('setNextScreen', obj.next)
            commit('setActiveScreen', Screens.VIDEO)
        },
        gotoMoodle({state, commit}, result_id) {
             return new Promise((resolve, reject) => {
                try {
                    axios.post(`/api/gotolink/${result_id}/`).then((response) => {
                        resolve(response.data);
                    })

                } catch (error) {
                    reject(error);
                }
            });
        }
    },
    modules: modules,
});
