import {VideoAssets} from "~/application/constants";

export function tryFullScreen() {
    if (document.fullscreenEnabled) {
        console.log("fullscreen supported")
        document.body.requestFullscreen();
    } else {
        console.log("fullscreen supported ?")
    }
}

export function preventMotion(event) {
    window.scrollTo(0, scrollOffset / 2);
    event.preventDefault();
    event.stopPropagation();
}

export function getWidth(el, type) {
  if (type === 'inner') // .innerWidth()
    return el.clientWidth;
  else if (type === 'outer') // .outerWidth()
    return el.offsetWidth;
  let s = window.getComputedStyle(el, null);
  if (type === 'width') // .width()
    return el.clientWidth - parseInt(s.getPropertyValue('padding-left')) - parseInt(s.getPropertyValue('padding-right'));
  else if (type === 'full') // .outerWidth(includeMargins = true)
    return el.offsetWidth + parseInt(s.getPropertyValue('margin-left')) + parseInt(s.getPropertyValue('margin-right'));
  return null;
}

export function getHeight(el, type) {
  if (type === 'inner') // .innerHeight()
    return el.clientHeight;
  else if (type === 'outer') // .outerHeight()
    return el.offsetHeight;
  let s = window.getComputedStyle(el, null);
  if (type === 'height') // .height()
    return el.clientHeight - parseInt(s.getPropertyValue('padding-top')) - parseInt(s.getPropertyValue('padding-bottom'));
  else if (type === 'full') // .outerHeight(includeMargins = true)
    return el.offsetHeight + parseInt(s.getPropertyValue('margin-top')) + parseInt(s.getPropertyValue('margin-bottom'));
  return null;
}

export function GetVideo(episode, moment){
    if(moment !== 'intro' && moment !== "outro")
        throw "Invalid moment, must be 'intro' or 'outro'."

    let name = ""
    switch (episode){
        case 1: name+= "ph_"
        break
        case 2: name+= "ch_"
        break
        default: name+= "bio_"
    }
    name += moment

    return VideoAssets[name]

}