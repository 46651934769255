<script>
import {Game} from "@/application/game";
import {mapMutations, mapState, mapGetters, mapActions} from "vuex";
import store from "@/store/store";
import * as _ from "lodash"

export default {
  props: ['question'],
  watch: {
    // selectedAnswer: function (newAnswer) {
    //
    //   if (this.question.type != 'mcq' || (this.question.type == 'mcq' && this.question.multiple_answer_allowed === false)) {
    //     Game.processQuizAnswer(newAnswer)
    //     this.clearCurrentQuestion()
    //   }
    //
    // }
  },
  data() {
    return {
      selectedAnswer: null
    }
  },
  computed: {
    ...mapState(['interaction']),
    ...mapState('question', ['questionDone'])
  },
  methods: {
    ...mapGetters(['getInteractionFromId']),
    ...mapMutations(["setInteraction", "setDialogue"]),
    ...mapMutations("question", ["clearCurrentQuestion", "setCurrentQuestion", "addQuestionDone"]),
    ...mapActions("question", ["postQuestionAnswer"]),
    ...mapActions(["getVisitedQuestions"]),
    validate: function () {
      let answer = {qid: this.question.id, answer: this.selectedAnswer}
      this.postQuestionAnswer(answer)
          .then((data) => {
            let retry = this.questionDone.indexOf(answer.qid) !== -1
            this.addQuestionDone(answer.qid)
            Game.processQuizAnswer(data, answer.qid)

            if (data.correct) {
              if (this.interaction.question.feedback) {
                this.setDialogue({type: 'feedback', data: this.interaction})
              }
              if (this.interaction.next !== null) {
                let nextInteraction = this.$store.state.map
                    .interactions.find(i => i.id === this.interaction.next.id)
                this.setInteraction(nextInteraction)
                this.setCurrentQuestion(nextInteraction.question)

              } else {
                this.clearCurrentQuestion()
              }
            } else {
              //todo negative feedback before closing
              if (this.interaction.question.feedback_neg !== "")
                this.setDialogue({type: 'feedback_neg', data: this.interaction})
              else {
                let temp = _.cloneDeep(this.interaction)
                temp.question.feedback_neg = "Je ne pense pas que ce soit juste, vérifie tes données."
                this.setDialogue({type: 'feedback_neg', data: temp})

                // this.clearCurrentQuestion()
              }

            }
            this.getVisitedQuestions()
          })

    }
  }

}
</script>