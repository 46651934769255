<template>
  <GameScreen :question="question">
    <template name="header">
    </template>
    <component :is="question.type" :question="question"></component>
  </GameScreen>
</template>


<script>
import McqHandlerQuestion from "@/components/screens/QuestionType/McqHandlerQuestion.vue"
import InputQuestion from "@/components/screens/QuestionType/InputQuestion.vue"
import MatchingQuestion from "@/components/screens/QuestionType/MatchingQuestion.vue"
import SelectQuestion from "@/components/screens/QuestionType/SelectQuestion.vue"
import GameScreen from "@/components/screens/GameScreen.vue"

import {mapState, mapActions, mapMutations} from 'vuex'
import {SoundManager} from "@/application/sounds";
import {Screens} from "@/application/constants";

export default {
  name: 'QuestionContentScreen',
  data() {
    return {}
  },
  components: {
    GameScreen,
    'mcq': McqHandlerQuestion,
    'free': InputQuestion,
    'match': MatchingQuestion,
    'select-question': SelectQuestion,
  },
  created() {

  },
  computed: {
    ...mapState("question", {
      question: state => state.currentQuestion
    })
  },
  methods: {
    ...mapActions("question", {
      getQuestionById: "getQuestionById"
    }),
    ...mapMutations("question", ["clearCurrentQuestion"]),
    close() {
      SoundManager.playSound('snd_croix')
      this.clearCurrentQuestion()
    }
  },
  mounted() {

  }
}
</script>