<template>
  <div class="template-wrapper inner-content">
    <div class="wrapper question-template question-template qcm-single ">
      <div class="screen-subtitle mx-auto" v-html="question.statement">
      </div>

      <div class="d-flex question-wrapper justify-content-center row flex-nowrap">
        <div
            class="col-4 col-lg-6  pt-3  px-2 px-lg-3  img-container d-flex align-items-start justify-content-center">
          <img :src="question.illustration" alt=""/>
        </div>

        <div class="col-8 col-lg-6 px-lg-3 px-2  pt-lg-1 ">
          <button class="scroll-buttons arrow small up inactive d-none">
            <arrow-svg/>
          </button>
          <form class="question-container">
            <div class="d-flex flex-column position-relative scroll-question justify-content-start">
              <button v-for="answer in question.answers" :key="answer.id" class="button question question-group"
                      @click.prevent="setModel(answer)">{{ answer.text }}
              </button>
            </div>
          </form>
          <button class="scroll-buttons arrow small down d-none inactive">
            <arrow-svg/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "McqImgQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  methods: {
    setModel: function (answer) {
      this.selectedAnswer = answer
      this.validate()
    }
  }
};
</script>
