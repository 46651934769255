import {Graphics, Container} from 'pixi.js'

export class Character extends Container {
    frameCount = 0
    w = 224 // or 181
    w2 = 181 // handle different exports
    h = 256

    animSpeedIdle = .2
    animSpeedWalk = .5

    constructor() {
        super();
        this.speed = 10
        this.gridCoords = {x: 0, y: 0}
        this.destination = new PIXI.Point()
        this.distanceFromTarget = 0
        this.direction = new PIXI.Point()

        this.sheets = {}
        this.walk = false
        this.path = []
        this.destinationReached = true

        // line to destination
        // this.arrowToDest = new Graphics()
        //this.parent.addChild(this.arrowToDest)


        // placeholder for character
        // this.graphics = new Graphics()
        // this.graphics.lineStyle(5, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        // this.graphics.beginFill(0xFFFFFF, 0);
        // this.graphics.drawCircle(0, 0, 100);
        // this.graphics.endFill();
        // this.addChild(this.graphics)

        this.lastUpdate = 0
    }

    calculateFrameWidth(spriteSheetWidth){
        return spriteSheetWidth % this.w === 0 ? this.w : this.w2
    }

    setupSpriteSheet(characterIndex, mapName) {
        //shadow under character
        this.removeChildren()

        this.graphics = new Graphics()
        this.graphics.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        this.graphics.beginFill(0, .2);
        this.graphics.drawEllipse(65, 108, 55, 12);
        this.graphics.endFill();
        this.addChild(this.graphics)

        let walkName = "walk_" + characterIndex
        let idleName = "idle_" + characterIndex
        if (mapName.includes("mars")) {
            walkName += "_mars"
            idleName += "_mars"
        }

        this.ssWalk = new PIXI.BaseTexture.from(PIXI.Loader.shared.resources[walkName].url)
        this.ssWait = new PIXI.BaseTexture.from(PIXI.Loader.shared.resources[idleName].url)

        let fw = this.calculateFrameWidth(this.ssWalk.width)

        this.sheets['marche'] = []
        for (let i = 0; i <= 5; i++) {
            this.sheets['marche'].push(
                new PIXI.Texture(this.ssWalk, new PIXI.Rectangle(i * fw, 0, fw, this.h)),
            )
        }

        fw = this.calculateFrameWidth(this.ssWait.width)
        this.sheets['attente'] = []
        for (let i = 0; i <= 17; i++) {
            this.sheets['attente'].push(
                new PIXI.Texture(this.ssWait, new PIXI.Rectangle(i * fw, 0, fw, this.h)),
            )
        }

        this.characterAnim = new PIXI.AnimatedSprite(this.sheets['attente'])
        this.characterAnim.anchor.set(.5)
        this.characterAnim.animationSpeed = this.animSpeedIdle
        this.characterAnim.loop = true
        this.characterAnim.play()
        this.characterAnim.position.set(64, -16)
        this.addChild(this.characterAnim)
    }


    setGridPosition(x, y) {
        this.gridCoords.x = x
        this.gridCoords.y = y
    }

    getGridPosition() {
        return this.gridCoords
    }

    setPath(coordsArray) {
        //console.log(coordsArray)
        this.path = coordsArray.slice(1)
        this.characterAnim.textures = this.sheets['marche']
        this.characterAnim.animationSpeed = this.animSpeedWalk

        this.characterAnim.play()
        this.walk = true
        this.graphics.position.set(0, -8)
    }

    setDestination(point) {
        //console.log(`setting destination to ${point[0]}x${point[1]}`)
        this.destination = {x: point[0], y: point[1]}

        let toTarget_x = this.destination.x - this.position.x
        if (toTarget_x < 0) this.characterAnim.scale.x = -1
        else if (toTarget_x > 0) this.characterAnim.scale.x = 1
        this.destinationReached = false
    }

    clearDestination() {
        this.destinationReached = true
        this.path = []
    }

    animate(dt) {
        this.lastUpdate += dt
        // if (this.lastUpdate > 60) {
        //     console.log(this.distanceFromTarget)
        //     this.lastUpdate = 0
        // }

        //draw line to destination
        // this.arrowToDest.clear()
        // this.arrowToDest.lineStyle(10, 0xFF0000, 1)
        // this.arrowToDest.beginFill(0xffFF00, 0.5);
        // this.arrowToDest.moveTo(this.position.x, this.position.y)
        // this.arrowToDest.lineTo(this.destination.x, this.destination.y)
        // this.arrowToDest.closePath()
        // this.arrowToDest.endFill()

        if (!this.destinationReached) {
            let newPos = this.moveToTarget(this.position, this.destination, dt * this.speed)
            this.position.set(newPos[0], newPos[1])
        } else if (this.path.length > 0) {
            this.setDestination(this.path.shift())
            let newPos = this.moveToTarget(this.position, this.destination, dt * this.speed)
            this.position.set(newPos[0], newPos[1])
        } else {
            if (this.walk) {
                //console.log("path ended, stop walking", this.position)
                this.characterAnim.textures = this.sheets['attente']
                this.characterAnim.animationSpeed = this.animSpeedIdle
                this.characterAnim.play()
                this.walk = false
                this.graphics.position.set(0)
            }
        }
    }

    moveToTarget(pos, target, maxDelta) {
        let toTarget_x = target.x - pos.x
        let toTarget_y = target.y - pos.y

        let sqdist = toTarget_x * toTarget_x + toTarget_y * toTarget_y
        if (sqdist === 0 || (maxDelta >= 0 && sqdist <= maxDelta * maxDelta)) {
            this.destinationReached = true

            this.setGridPosition(Math.floor(target.x / 128), Math.floor(target.y / 128))
            return [target.x, target.y]
        }

        let dist = Math.sqrt(sqdist)
        return [
            pos.x + toTarget_x / dist * maxDelta,
            pos.y + toTarget_y / dist * maxDelta
        ]
    }
}