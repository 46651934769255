<template>
  <GameScreen>
    <div class="screen-title">
      {{ course }}
      <span></span>
    </div>
    <button id="close_button" class="button round">
      <span class="innerborder" @click.prevent="backToMenu">X</span>
      <span class="circle "></span>
    </button>
    <div id="courselist" class="inner-content full d-flex">
      <div class="wrapper full align-items-center px-3 ">
        <div class="inner-wrapper d-flex w-100 pt-4 px-5">
          <div class="col-1 d-flex">
            <button class="scroll-buttons arrow round m-auto" v-if="allowCourseSwitching && activeCourse > 0"
                    @click.prevent="doPrevious">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/></svg>
              </span>
              <span class="circle"></span>
            </button>
          </div>
          <div class="col-10 ">
            <div v-if="activeCourse === 0" class="screen-subtitle mx-lg-auto mb-4">
              Aide du diagnostic
            </div>
            <div v-else class="screen-subtitle mx-lg-auto">
              Matières à reviser pour te mettre à niveau
            </div>

            <div v-if="activeCourse === 0" class="text-white help-wrapper">
              <p>L'écran de diagnostic t'aide à voir quelles sont les matières qu'il te faut réviser pour te mettre à
              niveau. Selon les réponses que tu as fournies et ton nombre de tentatives, un score est calculé pour
              identifier où se trouvent tes lacunes.</p>
              <p class="border-bottom fw-bold d-inline-flex mb-3 light-blue-text">À côté de chaque thématique testée se trouve un résultat différent :</p>
              <div class="d-flex question-group align-items-center p-0"><div class="validation-wrapper ">?</div> Tu dois encore répondre à des questions dans le jeu pour qu’on puisse évaluer ton score.</div>
              <div class="question-group d-flex align-items-center p-0"><div class="validation-wrapper true">V</div> Tu as l’air de connaître cette matière, bravo !</div>
              <div class="question-group d-flex align-items-center p-0"><div class="validation-wrapper false">X</div> Tu as encore des difficultés sur le sujet.</div>
              <p class="pb-4">En cliquant sur une matière, tu seras dirigé vers un site contenant des ressources (vidéos, exercices, …)
              pour retravailler la matière. Il te faudra créer un identifiant sur ce site et faire une auto-inscription
              aux modules. Consulte bien attentivement les matières où tu as éprouvé des difficultés, et n’hésite pas à
              parcourir celles où tu te sens à l’aise afin d’appréhender ta première année supérieure d’un pas
              totalement serein !</p>

            </div>
            <div v-else-if="!loading" class="overflow-hidden d-flex justify-content-center w-100 pt-3 flex-wrap">
              <template v-for="result in results">
                <div :key="result.id" class="beveledbutton-wrapper  results flex-column ">
                  <a :class="{'true' : result.user_score >= result.threshold,
                  'false' : result.user_score !== null && result.user_score < result.threshold}"
                     :href="result.link" target="_blank" class="beveled-button" @click="followLink(result.id)">
                    <span class=" d-flex align-items-center text-left p-2">{{ result.name }}</span>
                    <span class="validation-wrapper ">
                      <template v-if="result.user_score === null">?</template>
                      <template v-else-if="result.user_score >= result.threshold">V</template>
                      <template v-else>X</template>
                    </span>
                  </a>
                </div>
              </template>

            </div>
            <div v-else class="d-flex h-75 align-content-center">
              <div class="loader"
                   style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                <span>Chargement&nbsp;</span>
                <div class="lds-dual-ring"></div>
              </div>
            </div>
          </div>
          <div class="col-1 d-flex">
            <button class="scroll-buttons arrow round right m-auto"
                    v-if="allowCourseSwitching && activeCourse < 3"
                    @click.prevent="doNext">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/>
</svg>
              </span>

              <span class="circle"></span>
            </button>
          </div>
        </div>

      </div>
    </div>
    <!-- todo fix layout to avoid masking pagination arrow -->
    <!--    <template v-slot:character>-->
    <!--      <div id="advice-holder" class="d-flex flex-column position-absolute">-->
    <!--        <div class="bubble-text "><p>Cette page se modifie selon les résultats que tu as obtenu</p>-->
    <!--        </div>-->
    <!--        <img :src="characterImg" alt="" class="character-mini">-->
    <!--      </div>-->
    <!--    </template>-->
  </GameScreen>
</template>
<style lang="scss" scoped>
$BiseauTopLeftSmall: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);

#web-interface .beveledbutton-wrapper {
  min-height: 60px;
  margin-bottom: 10px;
  width: calc(100% - 50px);

  &:before {
    display: none;
  }

  &:last-of-type {
    margin-bottom: 35px;
  }
}

#web-interface .beveledbutton-wrapper, #web-interface .beveledbutton-wrapper:before, #web-interface .beveledbutton-wrapper a {
  clip-path: $BiseauTopLeftSmall;
}

#web-interface .beveledbutton-wrapper.results a {
  color: #88eeff;
  text-transform: uppercase;
  display: flex;
  background-color: #1c2664;
  height: 54px;
  font-weight: 900;
  padding-left: 10px;
  top: 3px;
  clip-path: polygon(5% 0%, 80% 0%, 100% 0, 100% 100%, 95% 100%, 20% 100%, 0 100%, 0 15%);
  justify-content: space-between;
  padding-right: 0;
  text-decoration: none;
}

 .validation-wrapper {
  background-color: #101646;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  font-family: "Anton", sans-serif;
  color: #2eaade;
  font-weight: 900;
  font-size: 40px;
   margin: 0 10px 0 0;
}
#web-interface .beveledbutton-wrapper .validation-wrapper{
  margin: 0 0 0 auto;
  border-left: solid 2px #88eeff;
}
#web-interface .true .validation-wrapper,#web-interface .true.validation-wrapper{
  color: #6acbdc;
}

#web-interface .false .validation-wrapper, #web-interface .false.validation-wrapper {
  color: #fe0000;
}

#web-interface .beveledbutton-wrapper a.false {
  background-color: #630c0c;
  color: white;
}

#web-interface .screen-subtitle {
  width: 100%;
}
.help-wrapper{
  .border-bottom{
    border-bottom-color: #a8e3f6 !important; ;
  }
}
@media screen and (min-width: 992px) {
  #web-interface .beveledbutton-wrapper {
    width: 75%;
  }
  #web-interface .screen-subtitle {
    width: unset;
  }
}

.loader span {
  color: #a8e3f6;
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Anton", sans-serif !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>

<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {Screens} from "@/application/constants";

export default {
  name: "ResultsScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      loading: true,
      courseIndex: 0,
    }
  },
  created() {
    this.setCourse(this.activeCourse).catch((error) => {
      console.log(error)
      console.log('%c[CourseListScreen] Error - loading default data', 'background: #222; color: #bada55')
      this.$store.commit('results/setResults', this.$store.state.results.mockResults)
    }).finally(() => {
      // this.initPagination().then(() => {
      //   this.setPage(0)
      // })
      this.loading = false
    })
    this.setCharacterMini('ava')
  },
  computed: {
    ...mapState(['activeCourse', 'characterMini']),
    ...mapState('results', ['results', 'pagination', 'allowCourseSwitching']),
    ...mapGetters("results", ["courseNameById"]),
    course() {
      return this.courseNameById(this.activeCourse)
    },
    characterImg() {
      return `/static/vendors/images/npc/${this.characterMini.identifier}.png`
    },
  },
  methods: {
    ...mapActions(["setCourse", "gotoMoodle"]),
    ...mapActions("results", ["getResults", "getResultsForEpisode",]),
    ...mapMutations(["setActiveScreen", "setCharacterMini"]),
    doNext: function () {
      this.loading = true
      this.setCourse(parseInt(this.activeCourse) + 1).then(() => this.loading = false)
    },
    doPrevious: function () {
      this.loading = true
      this.setCourse(this.activeCourse - 1).then(() => this.loading = false)
    },
    backToMenu() {
      this.setActiveScreen(Screens.TITLE)
    },
    followLink(id){
      this.gotoMoodle(id).then(response => {
        console.log(response)
      })
    }
  }
}
</script>
