export default () => ({
    results: [],
    allowCourseSwitching: true,
    courseName: {
        0: 'Explication',
        1: 'Physique',
        2: 'Chimie',
        3: 'Biologie'
    },
    displayedResults: [],
    pagination: {
        itemPerPage: 8,
        page: 0,
        total: 0,
        maxPage: 0,
    },
    mockResults: [
        {
            id: 1,
            title: "Chap 1",
            won: true
        },
        {
            id: 2,
            title: "Chap 2",
            won: true
        },
        {
            id: 3,
            title: "Chap 3",
            won: false
        },
        {
            id: 4,
            title: "Chap 4",
            won: false
        },
        {
            id: 5,
            title: "Chap 5",
            won: true
        },
        {
            id: 6,
            title: "Chap 6",
            won: true
        },
        {
            id: 7,
            title: "Chap 7"
        },
        {
            id: 8,
            title: "Chap 8"
        },
        {
            id: 9,
            title: "Chap 9"
        },
        {
            id: 10,
            title: "Chap 10"
        },
        {
            id: 11,
            title: "Chap 11"
        },
        {
            id: 12,
            title: "Chap 12"
        },
        {
            id: 13,
            title: "Chap 13"
        },
        {
            id: 14,
            title: "Chap 14"
        },
        {
            id: 15,
            title: "Chap 15"
        },

    ]
});
