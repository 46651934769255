import axios from "axios";

function getDisplayedAchievements(state, page) {
    var itemPerPage = state.pagination.itemPerPage;

    var startIndex = page * itemPerPage;
    var endIndex = page * itemPerPage + itemPerPage;

    if (page === state.pagination.maxPage - 1) {
        endIndex = state.achievements.length
    }

    return state.achievements.slice(startIndex, endIndex)
}

export default {
    setPage({state, commit}, pageNumber) {
        return new Promise((resolve, reject) => {
            if (pageNumber < 0 || pageNumber >= state.pagination.maxPage) {
                reject({message: 'Bad page number'});
            }

            var toDisplay = getDisplayedAchievements(state, pageNumber);

            commit("setPaginationPage", pageNumber);
            commit("setAchievementsDisplayed", toDisplay)

            resolve();
        });
    },
    initPagination({state, commit}) {
        return new Promise((resolve, reject) => {
            var total = state.achievements.length;
            var itemPerPage = state.pagination.itemPerPage;

            var maxPage = Math.trunc(total / itemPerPage);
            if (total % itemPerPage > 0) {
                maxPage += 1
            }

            commit("setPaginationTotal", total);
            commit("setPaginationMaxPage", maxPage);

            resolve();
        });
    },
    getAchievements({state, commit}) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/api/achievements/`).then((response) => {
                    commit("setAchievements", response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    saveAchievement({state, commit, dispatch}, id) {
        return new Promise((resolve, reject) => {
            try {
                axios.post(`/api/sa/`, {id: id,}).then((response) => {
                    console.log(response.data.message)
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
};
