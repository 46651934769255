import {Graphics, Container} from 'pixi.js'
import store from "~/store/store";
import {Screens} from "~/application/constants";
import {SoundManager} from "@/application/sounds";

export class NPC extends Container {
    buttonsCounts = 3
    buttonSize = 45 //todo resize button according to screen size
    buttonMargin = 5 //todo resize button according to screen size
    arrowSize = 18

    sheets = {}
    animSpeed = .2

    upperLayer = null

    // mockupData = '{"id":2,"question":{"id":4,"answers":[{"id":9,"text":"Il continue à se déplacer sur une orbite circulaire.","correct":true,"question":4},{"id":10,"text":"Il s\'éloigne radialement de la Terre.","correct":false,"question":4},{"id":11,"text":"Il tombe comme une pierre vers la Terre.","correct":false,"question":4},{"id":12,"text":"Il se met en mouvement rectiligne suivant la tangente à l\'orbite circulaire.","correct":false,"question":4},{"id":13,"text":"Il tombe sur la Terre en suivant un mouvement en spirale.","correct":false,"question":4}],"identifier":"ph-02","intro":"Oh non, un morceau du fuselage vient de lâcher ! Aide-moi à prédire sa trajectoire pour le récupérer au plus vite !","statement":"Quel sera le mouvement de cet élément ?","illustration":null,"clue":"L\'objet qui se détache possède la même vitesse que le vaisseau et donc son mouvement sera le même.","feedback":"Merci d\'avoir récupéré le bout du fuselage. Tout est sous contrôle, maintenant !","skip_intro":false,"points":1.0,"type":"mcq","multiple_answer_allowed":false},"tag_label":"Le fuselage s\'est détaché !","next":""}'

    constructor(npcData) {
        super();
        this.data = npcData

        this.speed = 10
        this.gridCoords = {x: 0, y: 0}

        // placeholder for character
        // this.graphics = new Graphics()
        // this.graphics.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        // this.graphics.beginFill(0x2F959F, 1);
        // this.graphics.drawRect(0, 0, 256, 512);
        // this.graphics.endFill();
        // this.addChild(this.graphics)

        this.referenceId = this.data.character.reference

        this.upperLayer = new PIXI.Container()
        this.setupSpriteSheet()
        this.setupNametag()
        this.setupButtons()
    }

    setupSpriteSheet() {
        //do not draw shadow under computer fake NPC
        if (this.data.character.identifier !== 'lol2000') {
            this.graphics = new Graphics()
            this.graphics.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
            this.graphics.beginFill(0, .2);
            this.graphics.drawEllipse(110, 250, 55, 12);
            this.graphics.endFill();
            this.addChild(this.graphics)
        }

        let spritesheetRessourceName = `spritesheet-${this.data.character.identifier}`
        console.log(`npc spritesheet: ${spritesheetRessourceName}`)
        this.spritesheet = new PIXI.BaseTexture
            .from(PIXI.Loader.shared.resources[spritesheetRessourceName].url)
        let w = 224
        let h = 256

        this.sheets['attente'] = []

        for (let i = 0; i <= 17; i++) {
            this.sheets['attente'].push(
                new PIXI.Texture(this.spritesheet, new PIXI.Rectangle(i * w, 0, w, h)),
            )
        }

        this.characterAnim = new PIXI.AnimatedSprite(this.sheets["attente"])
        //this.characterAnim.anchor.set(0)
        this.characterAnim.animationSpeed = this.animSpeed
        this.characterAnim.loop = true
        this.characterAnim.gotoAndPlay(Math.floor(Math.random() * this.sheets['attente'].length))
        this.addChild(this.characterAnim)
    }

    setupNametag() {
        const style = new PIXI.TextStyle({
            fontSize: 28,
            //fontWeight: 'bold',
            fill: '#ffffff'
        });

        let tag = `${this.data.question.identifier} - ${this.data.tag_label}`
        const name = new PIXI.Text(tag, style);
        // const name = new PIXI.Text(this.data.tag_label, style);

        let triangle = new Graphics()
        triangle.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        triangle.beginFill(0xFFFFFF, 1);
        triangle.drawStar(0, 0, 3, this.arrowSize, this.arrowSize / 2, Math.PI);
        triangle.endFill();
        triangle.position.set(this.width / 2, 0) //todo adapt to character sprite size

        let nametag = new Graphics()
        nametag.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        nametag.beginFill(0x4D70F2, .8);
        nametag.drawRect(0, 0, name.width + 35 * 2, name.height + 10 * 2);
        nametag.endFill();
        nametag.pivot.x = nametag.width / 2;
        nametag.position.set(this.width / 2, -triangle.height - nametag.height)


        name.pivot.set(name.width / 2, name.height / 2)
        name.position.set(nametag.width / 2, nametag.height / 2)

        this.upperLayer.addChild(nametag)
        this.upperLayer.addChild(triangle)
        nametag.addChild(name)

    }

    setupButtons() {
        this.buttons = []
        this.buttonsContainer = new PIXI.Container()

        let quizButton = new PIXI.Sprite(PIXI.Loader.shared.resources['images/ui/menu-question.jpg'].texture)
        quizButton.interactive = true
        quizButton.buttonMode = true;
        quizButton.on('pointerup', () => {
            //SoundManager.playSound('snd_parabole')
            store.commit('setDialogue', {type: 'intro', data: this.data})
            store.commit('setCharacterMini', this.data.character.identifier)
            store.commit("question/setCurrentQuestion", this.data.question)
            store.commit('setInteraction', this.data)
            this.changeQuestionColor()
        })
        this.buttons.push(quizButton)
        this.buttonsContainer.addChild(quizButton)

        if (this.data.character.reference) {
            let referenceButton = new PIXI.Sprite(PIXI.Loader.shared.resources['images/ui/menu-references.jpg'].texture)
            referenceButton.interactive = true
            referenceButton.buttonMode = true;
            referenceButton.on('pointerup', () => {
                SoundManager.playSound('snd_quiz')
                store.commit('setCharacterMini', this.data.character.identifier)
                store.commit('setReferenceToGuess', this.data.character.reference)
            })
            referenceButton.position.y = this.buttonSize + this.buttonMargin
            this.buttons.push(referenceButton)
            this.buttonsContainer.addChild(referenceButton)
        } else {
            for (let i = 1; i < this.buttonsCounts - 1; i++) {
                let button = this.createPlaceholderButton()
                button.position.y = i * (this.buttonSize + this.buttonMargin)
                this.buttons.push(button)
                this.buttonsContainer.addChild(button)
            }
        }


        // let clueButton = new PIXI.Sprite(PIXI.Loader.shared.resources['images/ui/menu-indice.jpg'].texture)
        // clueButton.interactive = true
        // clueButton.buttonMode = true;
        // clueButton.on('pointerup', () => {
        //     //SoundManager.playSound('snd_excmark')
        //     store.commit('setDialogue', {type: 'clue', data: this.data})
        //     this.changeClueColor()
        // })
        // clueButton.position.y = 2 * (this.buttonSize + this.buttonMargin)
        // this.buttons.push(clueButton)
        // this.buttonsContainer.addChild(clueButton)

        this.buttonsContainer.x = this.characterAnim.width * 0.8 + this.buttonMargin
        this.buttonsContainer.y = this.buttonsContainer.height / 2

        this.upperLayer.addChild(this.buttonsContainer)
    }

    changeQuestionColor() {
        this.buttons[0].texture = PIXI.Loader.shared.resources['images/ui/menu-question-out.jpg'].texture
    }

    changeReferenceColor() {
        this.buttons[1].texture = PIXI.Loader.shared.resources['images/ui/menu-references-out.jpg'].texture
    }

    changeClueColor() {
        this.buttons[2].texture = PIXI.Loader.shared.resources['images/ui/menu-indice-out.jpg'].texture
    }

    createPlaceholderButton() {
        let button = new Graphics()
        // Rectangle
        button.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        button.beginFill(0xFFFF9B, 1);
        button.drawRect(0, 0, this.buttonSize, this.buttonSize);
        button.endFill();

        return button
    }
}