<template>
  <div>
    <div v-show="screenType === screens.GAME_MAP">
      <button id="close_button" class="button round">
        <span class="innerborder" @click.prevent="showHelp">?</span>
        <span class="circle "></span>
      </button>
      <div id="game-container" class="scalable-wrapper"></div>
      <div v-if="currentQuestion" class="screen-container">
        <QuestionContentScreen v-bind:question-id="currentQuestion.id"/>
      </div>
    </div>
    <div v-if="screenType === screens.TITLE">
      <TitleScreen v-on:erase-progress="eraseProgression"></TitleScreen>
    </div>
    <div v-if="screenType === screens.HELP">
      <HelpScreen></HelpScreen>
    </div>
    <div v-if="screenType === screens.CHARACTER_SPEAKING">
      <CharacterTalkScreen></CharacterTalkScreen>
    </div>
    <div v-show="screenType === screens.EPISODES_LIST">
      <LoadingScreen v-if="!dataLoaded"></LoadingScreen>
      <EpisodeScreen v-else></EpisodeScreen>
    </div>
    <div v-if="screenType === screens.AVATAR_SELECT">
      <!--      <LoadingScreen v-if="!dataLoaded"></LoadingScreen>-->
      <AvatarScreen></AvatarScreen>
    </div>
    <div v-if="screenType === screens.RESULTS">
      <ResultsScreen></ResultsScreen>
    </div>
    <div v-if="screenType === screens.REFERENCES">
      <ReferenceScreen></ReferenceScreen>
    </div>
    <div v-if="screenType === screens.LOGIN">
      <LoginScreen></LoginScreen>
    </div>
    <div v-if="screenType === screens.REGISTER">
      <RegisterScreen></RegisterScreen>
    </div>
    <div v-if="screenType === screens.VIDEO">
      <VideoScreen></VideoScreen>
    </div>
    <RotatePhoneOverlay></RotatePhoneOverlay>
  </div>
</template>

<script>
import {Game} from '@/application/game.js'
import QuestionContentScreen from "@/components/screens/QuestionContentScreen.vue"
import TitleScreen from "@/components/screens/TitleScreen.vue"
import EpisodeScreen from "@/components/screens/EpisodeScreen.vue"
import AvatarScreen from "@/components/screens/AvatarScreen.vue"
import ResultsScreen from "@/components/screens/ResultsScreen.vue"
import CharacterTalkScreen from "@/components/screens/CharacterTalkScreen.vue"
import ReferenceScreen from "@/components/screens/ReferenceScreen.vue"
import LoginScreen from "@/components/screens/LoginScreen.vue"
import RegisterScreen from "@/components/screens/RegisterScreen.vue"
import {mapActions, mapMutations, mapState} from "vuex";
import {Screens} from "~/application/constants";
import LoadingScreen from "@/components/screens/LoadingScreen.vue";
import VideoScreen from "~/components/screens/VideoScreen.vue";
import RotatePhoneOverlay from "@/components/utils/RotatePhoneOverlay.vue";
import HelpScreen from "@/components/screens/HelpScreen.vue";

export default {
  name: 'App',
  watch: {
    currentQuestion: function (newValue) {

    }
  },
  data() {
    return {
      screens: Screens,
    }
  },
  computed: {
    ...mapState({screenType: 'activeScreen', dataLoaded: 'dataLoaded'}),
    ...mapState('question', ['currentQuestion']),
    ...mapState('account', ['hasCsrf']),
  },
  components: {
    RotatePhoneOverlay,
    VideoScreen,
    QuestionContentScreen,
    TitleScreen,
    EpisodeScreen,
    AvatarScreen,
    ResultsScreen,
    CharacterTalkScreen,
    ReferenceScreen,
    LoginScreen,
    RegisterScreen,
    LoadingScreen,
    HelpScreen,
  },
  created() {
    this.getEpisodes().then(() => {
      Game.init()
    })
    // if (this.hasCsrf === false) {
      this.getCsrfToken()
    // }
  },
  methods: {
    ...mapMutations(['setActiveScreen', 'setLastScreen', 'setVisitedQuestions', "setDataLoaded"]),
    ...mapActions(["getEpisodes", "getVisitedQuestions", "eraseProgress"]),
    ...mapActions("question", ["getQuestionById"]),
    ...mapActions("account", ["getCsrfToken"]),
    ...mapActions("achievements", ['getAchievements']),
    ...mapMutations("achievements", ['eraseAchievements']),
    ...mapMutations("question", ['clearQuestionDone', 'clearQuestionCounted']),

    showHelp(){
      this.setLastScreen(Screens.GAME_MAP)
      this.setActiveScreen(Screens.HELP)
    },
    eraseProgression() {
      console.warn('erasing progression')
      this.eraseAchievements(null)
      this.setVisitedQuestions({visited_questions: []})
      this.clearQuestionDone()
      this.clearQuestionCounted()
      this.setDataLoaded(false)
      this.eraseProgress().then(() => {
        this.getEpisodes().then(() => this.setDataLoaded(true))
        this.getVisitedQuestions()
        this.getAchievements()
      })
    }
  },
  mounted() {
    console.log(this.screenType)
  }
}
</script>

<style lang="scss" scoped>
#game-container {
  background: url("@/../vendors/images/ciel-espace.jpg") center center no-repeat;
  background-size: cover;
}

.screen-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
