import {getWidth, getHeight, preventMotion, GetVideo} from "~/application/helpers";
import {Inject} from "@/libs/pixi-tiled"
import * as PIXI from 'pixi.js'
import * as dragonBones from '@/../vendors/libs/dragonBones'
import * as Stats from 'stats.js'
import {Character} from "@/application/entities/character"
import {NPC} from "@/application/entities/npc"
import {Reference} from "@/application/entities/reference"
import store from "@/store/store"
import {sandbox} from "~/application/episodes/sandbox";
import {Teleport} from "~/application/entities/teleport";
import {PlayerAssets, ReferenceAssets, Screens, SoundAssets} from "@/application/constants";
import * as PF from "pathfinding"
import * as _ from "lodash"
import {sound} from '@pixi/sound';
import {SoundManager} from "@/application/sounds";

Inject(PIXI);

window.PIXI = PIXI

const {transformFittedPoint} = require('object-fit-math')
const factory = dragonBones.PixiFactory.factory;

// let t = dragonBones.PixiFactory.factory
// console.log(t)


// $("#gameCanvas").click(function (ev) {
//    var parentOffset = $(this).offset();
//    //or $(this).offset(); if you really just want the current element's offset
//    var relX = ev.pageX - parentOffset.left;
//    var relY = ev.pageY - parentOffset.top;
//
//    console.log(`${relX}x${relY}`)
// })

//click sound not in preloader so it can be played directly
sound.add('snd_click', '/static/vendors/assets/sounds/click.mp3')


export let Game = {
    app: null,
    debug: false,
    displayStats: false,

    screenData: {
        w: 800,
        h: 600
    },
    mapData: {},
    gridUnit: 128,
    gridData: {x: 0, y: 0},
    popupWindow: {
        visible: false,
        componentName: null,
        character: null,
        interactionId: null,
        questionId: null,
    },
    pressedKeys: {},
    scrollSpeed: 20,

    levels: {
        mars: {mapAsset: "map-mars"},
        starship: {mapAsset: "map-starship"},
        capsule: {mapAsset: "map-capsule"},
        base: {mapAsset: "map-base"},
    },

    levelsCycle: null,
    levelIndex: 0,

    episodeId: 1,
    qCountTxt: null,
    refCountTxt: null,
    maxScore: 10,
    currentScore: 0,
    progressBar: null,
    progressBarMinWidth: 5,
    progressBarMaxWidth: 684,
    progressTxt: null,
    avatar: null,

    //Containers
    mapContainer: null,
    npcContainer: null,
    uiContainer: null,

    //PF
    grid: null,
    finder: null,
    path: null,
    debugPathLayer: null,
    debugCollisionLayer: null,
    debugNumberLayer: null,

    //NPC
    npcs: [],
    teleports: [],


    loadEpisode(episodeId, mapIndex = 0, reset = false) {
        this.npcs = []
        this.teleports = []
        SoundManager.stopMainTheme()
        if (reset) {
            this.episodeId = episodeId
            this.maxScore = store.getters.getEpisodeMaxScore(this.episodeId)
            this.currentScore = store.getters.getEpisodeCurrentScore(this.episodeId)
            store.commit("question/clearQuestionDone")
        }


        //let map = store.getters.getEpisodeFirstMap(id)
        let map = store.getters.getEpisodeMapIndex(episodeId, mapIndex)
        store.commit('setMap', map)
        if (this.debug) console.log(map)
        this.loadMap(map.tilemap_name)
        SoundManager.setAmbiance(map)

        this.npcContainer.removeChildren()
        map.teleports.forEach(teleport => this.createTeleport(teleport))
        map.interactions.forEach(interaction => this.createNPC(interaction))
        map.references.forEach(reference => this.createReference(reference))
        this.mapContainer.addChild(this.npcContainer)


        // this.mapContainer.x = -map.start_coord_x * this.gridUnit
        // this.mapContainer.y = -map.start_coord_y * this.gridUnit

        this.updateQuestionCount()
        store.dispatch("achievements/getAchievements").then(() => {
            this.updateRefCount()
            this.checkFoundReference()
        })
        this.updateScoreProgressBar()
        store.dispatch("setCourse", episodeId)

        this.char.clearDestination()
        this.char.setGridPosition(map.character_start_x, map.character_start_y)
        if (this.debug) console.log("start position for player: ", map.character_start_x, map.character_start_y)
        let charPos = this.gridPosToXY(this.char.getGridPosition())
        this.char.position.x = map.character_start_x * this.gridUnit
        this.char.position.y = map.character_start_y * this.gridUnit
        if (this.debug) console.log(this.char.position)
        this.npcContainer.addChild(this.char)

        this.npcs.forEach(npc => {
            if (npc instanceof NPC)
                npc.upperLayer.position.set(npc.position.x + 10, npc.position.y - 70)
            else
                npc.upperLayer.position.set(npc.position.x, npc.position.y)

            this.npcContainer.addChild(npc.upperLayer)
        })

        this.teleports.forEach(teleport => {
            console.log("teleport position", teleport.position, "data.width", teleport.data.width)
            console.log("upperlayer position before", teleport.upperLayer.position)

            teleport.upperLayer.position.set(teleport.position.x + this.gridUnit / 2,
                teleport.position.y)
            console.log("upperlayer position after", teleport.upperLayer.position)

            console.log("diff", teleport.upperLayer.position.x - teleport.position.x)
            this.npcContainer.addChild(teleport.upperLayer)
        })

        this.setAvatar(map.name)
        this.checkVisitedQuestions()

        if (this.debug) {
            if (!this.debugCollisionLayer)
                this.debugCollisionLayer = new PIXI.Container()
            if (!this.debugPathLayer)
                this.debugPathLayer = new PIXI.Container()
            this.displayCollision()
            this.mapContainer.addChild(this.debugCollisionLayer)
            this.mapContainer.addChild(this.debugPathLayer)
            //this.displayPath()
        }
        //todo remove this when not needed anymore
        let params = new URLSearchParams(window.location.search.substring(1));
        let showGridParam = params.get("grille")

        if (showGridParam) {
            if (!this.debugNumberLayer)
                this.debugNumberLayer = new PIXI.Container()
            this.displayGridNumber()
            this.mapContainer.addChild(this.debugNumberLayer)
        }
    },

    checkVisitedQuestions() {
        let qIds = store.state.visitedQuestions
        this.npcs.forEach(npc => {
            if (npc instanceof NPC && qIds.find(qid => qid === npc.data.question.id) !== undefined) {
                npc.changeQuestionColor()
                store.commit('question/addQuestionDone', npc.data.question.id)
            }
        })
        this.updateQuestionCount()
    },

    checkFoundReference() {
        let wons = store.state.achievements.achievements.filter(item => item.won === 1)
        this.npcs.forEach(npc => {
             if (wons.find(won => won.id === npc.referenceId) !== undefined) {
                npc.changeReferenceColor()
            }
        })
    },

    setupPathFindingGrid(tilemapSource) {
        let width = tilemapSource.width
        let height = tilemapSource.height
        if (this.debug) console.log('setting up new PF grid', width, height)
        this.grid = new PF.Grid(width, height)
        for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
                if (tilemapSource.data[x + y * width] !== 0) {
                    this.grid.setWalkableAt(x, y, false)
                }
            }
        }
    },


    loadMap(mapAsset) {
        if (this.debug) console.log(`loading map ${mapAsset}`)
        this.mapContainer.removeChildren()
        //this.mapContainer.position.set(this.app.screen.width / 2, this.app.screen.height / 2)
        let stage = _.cloneDeep(this.app.loader.resources[mapAsset].stage)
        let collisionLayer = stage.children.find(elem => elem.name === "collision")
        if (collisionLayer !== undefined) {
            stage.removeChild(collisionLayer)
            this.setupPathFindingGrid(collisionLayer.source)
            this.finder = new PF.AStarFinder({allowDiagonal: true, dontCrossCorners: true})
        }
        stage.interactive = true
        stage.on('pointerup', this.setPlayerPath.bind(this))

        this.mapContainer.addChild(stage);

        this.mapData.max_x = stage.layerWidth * this.gridUnit;
        this.mapData.max_y = stage.layerHeight * this.gridUnit;

        if (this.debug) console.log(`map size: ${this.mapData.max_x}x${this.mapData.max_y}`)
        this.gridData.x = stage.layerWidth
        this.gridData.y = stage.layerHeight
        if (this.debug) console.log(`grid size:${this.gridData.x}x${this.gridData.y}`)
    },
    nextLevel() {
        this.levelIndex++
        //this.app.stage.removeChildren()
        let levelKey = this.levelsCycle[this.levelIndex % this.levelsCycle.length]
        if (this.debug) console.log(`switching to map ${this.levels[levelKey].mapAsset}`)
        this.loadMap(this.levels[levelKey].mapAsset)
        this.mapContainer.addChild(this.npcContainer)
    },
    setupViewportSize() {
        this.screenData.w = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        this.screenData.h = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

        // ref 1920 / 128  = 15
        // always display 15 tiles ?
        let tileCounts = Math.ceil(this.screenData.w / this.gridUnit)
        let scale = 15 / tileCounts
        this.screenData.w *= scale
        this.screenData.h *= scale

    },
    resizeRenderer() {
        this.setupViewportSize()
        this.app.renderer.resize(this.screenData.w, this.screenData.h)
        this.resizeUI()
    },
    processQuizAnswer(response, qid) {
        this.updateQuestionCount()
        if (response.correct === true
            && store.state.question.questionCounted.find(id => id === qid) === undefined) {
            if (this.debug) console.log('not a retry')
            this.currentScore += response.points
            store.commit('question/addQuestionCounted', qid)
        }
        this.updateScoreProgressBar()
    },
    updateQuestionCount() {
        this.qCountTxt.text = `${store.state.question.questionDone.length}/${store.getters.getEpisodeQuestionCount(this.episodeId)}`
    },
    updateRefCount() {
        this.refCountTxt.text = `${store.getters["achievements/wonCount"]}/${store.state.achievements.achievements.length}`
    },
    updateScoreProgressBar() {
        if (this.debug) console.log('score', `${this.currentScore}/${this.maxScore}`)
        let scorePercent = this.currentScore / this.maxScore
        this.progressBar.width = scorePercent === 0 ? this.progressBarMinWidth : this.progressBarMaxWidth * scorePercent;
        this.progressTxt.text = `${Math.floor(scorePercent * 100)}%`
        this.progressTxt.position.x = this.progressBar.position.x + this.progressBar.width + 5
    },
    setPlayerPath(e) {
        let targetPoint = this.getEventPositionInView(e.data.originalEvent)
        let targetGridPos = this.XYPosToGrid(
            targetPoint.x + this.mapContainer.pivot.x,
            targetPoint.y + this.mapContainer.pivot.y
        )

        //this.char.setGridPosition(targetGridPos.x, targetGridPos.y)
        let charPos = this.XYPosToGrid(this.char.position.x, this.char.position.y)
        //let charPos = this.char.getGridPosition()

        if (this.debug) {
            console.log('event', e)
            console.log("targetPoint", targetPoint)
            console.log("targetGridPos", targetGridPos)
            console.log("charPos", charPos)
        }
        this.path = this.finder.findPath(charPos.x, charPos.y, targetGridPos.x, targetGridPos.y, this.grid.clone())
        if (this.debug) console.log(this.path)
        //this.path = PF.Util.smoothenPath(this.grid, this.path) // todo check for better smoothing
        if (this.debug) {
            this.displayPath()
        }
        this.char.setPath(this.path.map(coords => [coords[0] * this.gridUnit, coords[1] * this.gridUnit]))
    },
    getEventPositionInView(event) {
        let x, y
        if ('touchend' === event.type) {
            x = event.changedTouches[0].offsetX
            y = event.changedTouches[0].offsetY
        } else {
            x = event.offsetX
            y = event.offsetY
        }
        const canvasSize = this.app.view.getBoundingClientRect()
        const {objectFit, objectPosition} = getComputedStyle(this.app.view)
        const [left, top] = objectPosition.split(' ')
        let fittedPoint = transformFittedPoint({x, y}, canvasSize, this.app.view, objectFit, left, top)
        if (this.debug) {
            console.log("canvasSize:", canvasSize)
            console.log("objectFit", objectFit, " objectPosition", objectPosition)
            console.log(left, top)
            console.log("fittedPoint", fittedPoint)
        }
        return fittedPoint
    },
    init() {

        this.levelsCycle = Object.keys(this.levels)
        this.setupViewportSize()
        this.app = new PIXI.Application({
            width: this.screenData.w,
            height: this.screenData.h,
            //backgroundColor: 0x1099bb,
            antialias: false,
            sharedLoader: true,
            transparent: true
        });
        window.addEventListener('resize', this.resizeRenderer.bind(this))
        this.app.view.classList.add('app');
        document.getElementById('game-container').appendChild(this.app.view)

        this.displayStatsWidget()
        //this.app.view.addEventListener('click', this.setPlayerPath.bind(this))

        let loadedCallback = (loader, resources) => {
            if (this.debug) console.log(resources)
            this.setupStage()
            store.commit('setDataLoaded', true)
            window.addEventListener('keydown', this.keysDown.bind(this))
            window.addEventListener('keyup', this.keysUp.bind(this))
        }
        this.app.loader.add("map-mars", "/static/vendors/assets/mars-desert.json")
            .add("map-starship", "/static/vendors/assets/map-starship-v2.json")
            .add("map-capsule", "/static/vendors/assets/map-capsule-v2.json")
            .add("map-base", "/static/vendors/assets/map-base-v2.json")
            .add("map-test", "/static/vendors/assets/collisionTest.json")
            .add("mars-tileset", "/static/vendors/images/mars-psd.png")
            .add("ship-tileset", "/static/vendors/images/ship.png")
            .add("assets-tileset", "/static/vendors/images/assets.png")
            .add("marche-mars", "/static/vendors/images/pj_marche-mars.png")
            .add("marche", "/static/vendors/images/pj_marche.png")
            .add("attente-mars", "/static/vendors/images/pj_attente-mars.png")
            .add("attente", "/static/vendors/images/pj_attente.png")
            .add("sheldon_attente", "/static/vendors/images/sheldon_attente.png")
            .add("images/ui/menu-indice.jpg", "/static/vendors/images/ui/menu-indice.jpg")
            .add("images/ui/menu-question.jpg", "/static/vendors/images/ui/menu-question.jpg")
            .add("images/ui/menu-references.jpg", "/static/vendors/images/ui/menu-references.jpg")
            .add("images/ui/menu-indice-out.jpg", "/static/vendors/images/ui/menu-indice-out.jpg")
            .add("images/ui/menu-question-out.jpg", "/static/vendors/images/ui/menu-question-out.jpg")
            .add("images/ui/menu-references-out.jpg", "/static/vendors/images/ui/menu-references-out.jpg")
            .add("npc-ava", "/static/vendors/images/npc/ava.png")
            .add("npc-blub", "/static/vendors/images/npc/blub.png")
            .add("npc-emc2", "/static/vendors/images/npc/emc2.png")
            .add("npc-eternity", "/static/vendors/images/npc/eternity.png")
            .add("npc-kartoon", "/static/vendors/images/npc/kartoon.png")
            .add("npc-kipper", "/static/vendors/images/npc/kipper.png")
            .add("npc-marcsolo", "/static/vendors/images/npc/marcsolo.png")
            .add("npc-manugus", "/static/vendors/images/npc/manugus.png")
            .add("npc-mortimer", "/static/vendors/images/npc/mortimer.png")
            .add("npc-plock", "/static/vendors/images/npc/plock.png")
            .add("npc-replay", "/static/vendors/images/npc/replay.png")
            .add("npc-wlily", "/static/vendors/images/npc/wlily.png")
            .add("npc-lol2000", "/static/vendors/images/npc/lol2000.png")
            .add("spritesheet-ava", "/static/vendors/images/npc/spritesheets/ava.png")
            .add("spritesheet-blub", "/static/vendors/images/npc/spritesheets/blub.png")
            .add("spritesheet-emc2", "/static/vendors/images/npc/spritesheets/emc2.png")
            .add("spritesheet-eternity", "/static/vendors/images/npc/spritesheets/eternity.png")
            .add("spritesheet-kartoon", "/static/vendors/images/npc/spritesheets/kartoon.png")
            .add("spritesheet-kipper", "/static/vendors/images/npc/spritesheets/kipper.png")
            .add("spritesheet-marcsolo", "/static/vendors/images/npc/spritesheets/marcsolo.png")
            .add("spritesheet-manugus", "/static/vendors/images/npc/spritesheets/manugus.png")
            .add("spritesheet-mortimer", "/static/vendors/images/npc/spritesheets/mortimer.png")
            .add("spritesheet-plock", "/static/vendors/images/npc/spritesheets/plock.png")
            .add("spritesheet-replay", "/static/vendors/images/npc/spritesheets/replay.png")
            .add("spritesheet-wlily", "/static/vendors/images/npc/spritesheets/wlily.png")
            .add("spritesheet-lol2000", "/static/vendors/images/npc/spritesheets/lol2000.png")
            .add("images/interface.png", "/static/vendors/images/interface.png")
            .add("images/menu.png", "/static/vendors/images/menu.png")
            .add("images/progressbar.png", "/static/vendors/images/progressbar.png")
            .add("ui/player/01.png", "/static/vendors/images/ui/player/01.png")

        for (let name in SoundAssets) {
            this.app.loader.add(name, SoundAssets[name]);
        }

        for (let name in ReferenceAssets) {
            this.app.loader.add(name, ReferenceAssets[name]);
        }

        for (let name in PlayerAssets) {
            this.app.loader.add(name, PlayerAssets[name]);
        }

        this.app.loader.load(loadedCallback);
    },
    displayStatsWidget() {
        if (this.displayStats) {
            const stats = new Stats()
            document.body.appendChild(stats.dom)
            this.app.ticker.add(stats.update, stats)
        }
    },
    createNPC: function (npcData) {
        if (!npcData.visible) return
        let npc = new NPC(npcData)
        let npcPosData = npcData.hasOwnProperty('gridPos') ? {
            x: npcData.gridPos.x,
            y: npcData.gridPos.y
        } : {x: npcData.x_pos, y: npcData.y_pos}

        if (this.debug) console.log(`adding NPC at position ${npcPosData.x}:${npcPosData.y}`)
        let npcPos = this.gridPosToXY(npcPosData)
        npc.pivot.set(-12, 72)
        npc.position.set(npcPos.x, npcPos.y)
        this.npcContainer.addChild(npc)
        this.npcs.push(npc)

        //make npc a wall for PF
        this.grid.setWalkableAt(npcPosData.x, npcPosData.y, false)
        if (npcPosData.y >= 1)
            this.grid.setWalkableAt(npcPosData.x, npcPosData.y - 1, false)
    },
    createReference: function (referenceData) {
        let ref = new Reference(referenceData)
        let refPosData = {x: referenceData.x_pos + 1, y: referenceData.y_pos + 1}

        if (this.debug) console.log(`adding Reference at position ${refPosData.x}:${refPosData.y}`)
        let refPos = this.gridPosToXY(refPosData)
        //ref.pivot.set(-12, 72)
        ref.position.set(
            refPos.x + ref.data.x_offset - this.gridUnit / 2,
            refPos.y + ref.data.y_offset + this.gridUnit / 2
        )
        this.npcContainer.addChild(ref)
        this.npcs.push(ref)

        //todo : make unwalkable based on ref size
        // this.grid.setWalkableAt(refPosData.x, refPosData.y, false)
        // if (refPosData.y >= 1)
        //     this.grid.setWalkableAt(refPosData.x, refPosData.y - 1, false)
    },
    createTeleport: function (teleportData) {
        let teleport = new Teleport(teleportData, this.loadEpisode.bind(this))
        let npcPosData = {x: teleportData.x_pos, y: teleportData.y_pos}

        if (this.debug) console.log(`adding Teleport at position ${npcPosData.x}:${npcPosData.y}`)
        let teleportPos = this.gridPosToXY(npcPosData)
        teleport.position.set(teleportPos.x, teleportPos.y)
        this.npcContainer.addChild(teleport)

        this.teleports.push(teleport)
    },
    resizeUI: function () {
        let initialWidth = this.uiContainer.width
        console.log(`ui w: ${initialWidth}`, `renderer width: ${this.app.renderer.width}`)
        let refWidth = 1920
        let uiScale = this.app.renderer.width / refWidth

        this.uiContainer.scale.set(uiScale)
        this.uiContainer.position.x = (this.app.renderer.width - this.uiContainer.width) / 2
        this.uiContainer.position.y = this.app.renderer.height - (this.uiContainer.height / 2) - this.app.renderer.height / 40
    },
    setupUI: function () {
        this.uiContainer = new PIXI.Container()
        let hud = new PIXI.Sprite(PIXI.Loader.shared.resources['images/interface.png'].texture)
        let menuBtn = new PIXI.Sprite(PIXI.Loader.shared.resources['images/menu.png'].texture)
        this.avatar = new PIXI.Sprite(PIXI.Loader.shared.resources['ui/player/01.png'].texture)
        menuBtn.position.set(1580, 22)
        menuBtn.interactive = true
        menuBtn.buttonMode = true
        menuBtn.addListener('pointerup', this.backToMenu.bind(this))

        this.avatar.anchor.set(0, 1)
        this.avatar.position.set(0, 85)


        this.qCountTxt = new PIXI.Text(`0/${store.getters.getEpisodeQuestionCount(this.episodeId)}`,
            {fill: "#ffffff", fontSize: 32})
        this.qCountTxt.position.set(1276, 40)

        this.refCountTxt = new PIXI.Text(`${store.getters["achievements/wonCount"]}/${store.state.achievements.achievements.length}`,
            {fill: "#ffffff", fontSize: 32})
        this.refCountTxt.position.set(1456, 40)

        this.uiContainer.addChild(hud, menuBtn, this.qCountTxt, this.refCountTxt, this.avatar)

        this.progressBar = new PIXI.Sprite(PIXI.Loader.shared.resources['images/progressbar.png'].texture)
        this.progressBar.position.set(365, 42)
        this.progressBar.width = this.progressBarMinWidth

        this.progressTxt = new PIXI.Text(`0%`,
            {fill: "#ffffff", fontSize: 32})
        this.progressTxt.position.set(this.progressBar.position.x + this.progressBar.width + 5, 40)

        this.uiContainer.addChild(this.progressBar, this.progressTxt)

        this.resizeUI();
    },
    setAvatar(mapName) {
        let avatarIndex = store.state.selectedAvatarIndex.toString().padStart(2, '0')
        this.avatar.texture = PIXI.Loader.shared.resources[`ui_${avatarIndex}`].texture
        this.char.setupSpriteSheet(avatarIndex, mapName)
    },
    setupStage() {

        this.objToupdate = []
        this.mapContainer = new PIXI.Container()
        this.npcContainer = new PIXI.Container()

        this.app.stage.addChild(this.mapContainer)

        // todo enable sandbox mode
        // this.loadMap(this.levels.mars.mapAsset)
        // this.mapContainer.addChild(this.npcContainer)
        // sandbox.npcs.forEach(npcData => {
        //     this.createNPC(npcData);
        // })

        this.char = new Character()
        this.objToupdate.push(this.char)

        //mapContainer.addChild(char.arrowToDest)

        this.setupUI();
        this.app.stage.addChild(this.uiContainer)

        if (this.debug) {
            const textSprite = new PIXI.Text(`commandes:\n'n' change de map\n'ESC' ferme la question`,
                {fill: "#ff8000", fontSize: 18});
            const txtBG = new PIXI.Sprite(PIXI.Texture.WHITE);
            txtBG.width = textSprite.width + 10
            txtBG.height = textSprite.height + 10
            txtBG.position.set(5)
            textSprite.position.set(10)
            this.npcContainer.addChild(txtBG, textSprite)
        }

        this.app.ticker.add(this.gameLoop.bind(this));

        //this.loadEpisode(2, 0, true)


        //doResize()
    },
    backToMenu() {
        SoundManager.playSound('snd_click')
        store.commit("setActiveScreen", Screens.TITLE)
    },
    checkForEpisodeCompletion() {
        let done = store.state.question.questionDone.length
        let total = store.getters.getEpisodeQuestionCount(this.episodeId)
        if (done === total) {
            store.dispatch('playVideo', {video: GetVideo(this.episodeId, 'outro'), next: Screens.RESULTS})
            return true
        }
        return false
    },
    gameLoop(dt) {
        if (this.pressedKeys["87"] || this.pressedKeys["38"]) this.mapContainer.pivot.y -= this.scrollSpeed * dt // W
        if (this.pressedKeys["65"] || this.pressedKeys["37"]) this.mapContainer.pivot.x -= this.scrollSpeed * dt // A
        if (this.pressedKeys["83"] || this.pressedKeys["40"]) this.mapContainer.pivot.y += this.scrollSpeed * dt // S
        if (this.pressedKeys["68"] || this.pressedKeys["39"]) this.mapContainer.pivot.x += this.scrollSpeed * dt // D

        let charPosX = this.char.position.x - this.app.screen.width / 2
        let charPosY = this.char.position.y - this.app.screen.height / 2 + this.uiContainer.height / 2
        this.mapContainer.pivot.set(charPosX, charPosY)

        if (this.mapContainer.pivot.x < 0) this.mapContainer.pivot.x = 0
        if (this.mapContainer.pivot.y < 0) this.mapContainer.pivot.y = 0
        if (this.mapContainer.pivot.x > this.mapData.max_x - this.screenData.w)
            this.mapContainer.pivot.x = this.mapData.max_x - this.screenData.w
        if (this.mapContainer.pivot.y > this.mapData.max_y - this.screenData.h)
            this.mapContainer.pivot.y = this.mapData.max_y - this.screenData.h

        this.objToupdate.forEach(obj => {
            if (obj.animate && typeof obj.animate === "function") {
                obj.animate(dt)
            }
        })
    },
    disableInteractionReference(referenceId) {
        let interaction = this.npcs.find(npc => npc.referenceId === referenceId)
        interaction.changeReferenceColor()
    },
    gridPosToXY(gridCoords) {
        let _x = gridCoords.x * this.gridUnit - this.gridUnit / 2
        let _y = gridCoords.y * this.gridUnit - this.gridUnit / 2
        return {x: _x, y: _y}
    },
    XYPosToGrid(gridX, gridY) {
        let x = (gridX - this.mapContainer.x) / this.gridUnit
        let y = (gridY - this.mapContainer.y) / this.gridUnit
        return {x: Math.floor(x), y: Math.floor(y)}
    },

    keysUp(e) {
        this.pressedKeys[e.keyCode] = false
    },

    keysDown(e) {
        this.pressedKeys[e.keyCode] = true

        // N to cycle maps
        if (e.keyCode === 78) {
            this.nextLevel()
        }

        // Esc to close question
        if (e.keyCode === 27) {
            store.commit('question/clearCurrentQuestion')
        }
    },

    displayCollision() {
        console.log('Displaying Collision')
        this.debugCollisionLayer.removeChildren()
        if (!this.grid) {
            console.log("displayCollision: no grid set")
            return
        }
        let width = this.grid.width
        let height = this.grid.height
        for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
                if (!this.grid.isWalkableAt(x, y)) {
                    let collTile = new PIXI.Graphics()
                    collTile.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
                    collTile.beginFill(0x2F959F, .5);
                    collTile.drawRect(0, 0, this.gridUnit, this.gridUnit);
                    collTile.endFill();
                    collTile.position.set(x * this.gridUnit, y * this.gridUnit)
                    this.debugCollisionLayer.addChild(collTile)
                }
            }
        }
    },
    displayGridNumber() {
        console.log('Displaying grid Numbers')
        this.debugNumberLayer.removeChildren()
        let width = this.grid.width
        let height = this.grid.height
        for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
                let text = new PIXI.Text("x: " + x.toString() + "\ny: " + y.toString(), {
                    font: "32px Arial",
                    fill: "#00FF00"
                });
                text.position.x = x * this.gridUnit + 20;
                text.position.y = y * this.gridUnit + 20;
                this.debugNumberLayer.addChild(text);
            }
        }
    },
    displayPath() {
        this.debugPathLayer.removeChildren()

        this.path.forEach(coord => {
            let pathTile = new PIXI.Graphics()
            pathTile.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
            pathTile.beginFill(0xc41d52, .5);
            pathTile.drawRect(0, 0, this.gridUnit, this.gridUnit);
            pathTile.endFill();
            pathTile.position.set((coord[0]) * this.gridUnit, (coord[1]) * this.gridUnit)
            this.debugPathLayer.addChild(pathTile)
        })

    },
}


// window.document.addEventListener("DOMContentLoaded", function () {
//     window.console.log("dom ready 1")
//
// });

//region player resize


function doResize() {

    let el = window.document.getElementsByClassName("app")
    let elHeight = getHeight(el, 'outer')
    let elWidth = getWidth(el, "outer")

    let wrapper = window.document.getElementsByClassName('scalable-wrapper')

    let scale
    let scrollOffset
    let listenerSet = false

    scale = Math.min(
        window.innerWidth / elWidth,
        window.innerHeight / elHeight
    )

    let containerScaled = elHeight * scale
    scrollOffset = getHeight(wrapper, 'outer') - containerScaled

    el.style.transform = 'translate(-50%, -50%) ' + 'scale(' + scale + ')'

    if (scrollOffset > 0) {
        setTimeout(() => window.scrollTo(0, scrollOffset / 2), 50)
        if (!listenerSet) {
            window.addEventListener("scroll", preventMotion, false);
            window.addEventListener("touchmove", preventMotion, false);
            listenerSet = true
        }
    }
}

//endregion




