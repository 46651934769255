<template>

  <div class="template-wrapper">
    <div class="inner-content">
      <div class="wrapper question-template champlibre-container">
        <div class="screen-subtitle mx-auto" v-html="question.statement">
        </div>
        <div class="d-flex question-wrapper justify-content-center flex-wrap">
          <button class="scroll-buttons arrow small up inactive d-none w-100 ">
            <arrow-svg/>
          </button>
          <form class="question-container ">
            <div class="d-flex flex-column position-relative scroll-question  justify-content-start">
              <div class="question-group flex-column text-center justify-content-center d-flex">
                <div class="question ">
                  Encode ta réponse ci-dessous
                </div>
                <div class="d-flex mx-auto mt-1 mb-2 wrapper-input">
                  <input id="demo1-a" v-model="selectedAnswer" name="demo1" type="text">
                  <label for="demo1-a"> </label>
                  <span class="unit ">{{ question.unit }}</span>

                </div>
              </div>
              <div
                  :class="{'disabled' : (!selectedAnswer || selectedAnswer.length <= 0)}"
                  class="beveledbutton-wrapper submit-btn question-group button w-25 mx-auto">

                <button :disabled="!selectedAnswer || selectedAnswer.length <= 0" class=""
                        @click.prevent="processAnswer">
                  Valider
                </button>
              </div>


            </div>
          </form>

          <button class="scroll-buttons arrow small down d-none inactive  w-100 ">
            <arrow-svg/>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  input[type=text]{
    background-color: #17234d;
    color: white;
  }
  .wrapper-input{
    border: 2px solid #084cae;
  }
  .unit {
    width: 25px;
    margin-left: 0;
    color: #c1daff;
    background-color: #0e142f;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
</style>

<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "InputQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  methods:
      {
        processAnswer() {
          this.validate()
          this.selectedAnswer = null
        }
      }
}
;
</script>
