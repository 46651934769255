<template>

  <div class="inner-content">
    <div class="wrapper question-template check-box-container">
      <div class="screen-subtitle mx-auto" v-html="question.statement">
      </div>
      <div class="d-flex question-wrapper justify-content-cente flex-wrap">
        <button class="scroll-buttons arrow small up inactive d-none w-100 ">
          <arrow-svg/>
        </button>
        <form class="question-container ">

          <div class="d-flex flex-row position-relative scroll-question  justify-content-start">
            <div v-if="question.illustration"
                 class=" pt-3  px-2 px-lg-3  img-container d-flex align-items-start justify-content-center">
              <img :src="question.illustration" alt=""/>
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <div v-for="(answer, index) in question.answers" :key="answer.id"
                   class="question-group d-flex justify-content-between ">
                <div class="flex-grow-1 question" style="color: white" v-html="answer.text">
                </div>
                <div class="input-wrapper">
                  <input :id="'answer-'+index" :value="answer.id" v-model="selectedAnswer" class="true" type="checkbox">
                  <label :for="'answer-'+index"></label>
                </div>
              </div>
              <div class="beveledbutton-wrapper submit-btn question-group button w-25 mx-auto">
                <button @click.prevent="validate"> Valider</button>
              </div>
            </div>

          </div>
        </form>

        <button class="scroll-buttons arrow small down d-none inactive  w-100 ">
          <arrow-svg/>
        </button>

      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$light-blue: #88eeff;

#web-interface .check-box-container {
  .input-wrapper {
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    height: 30px;
    width: 30px;
    opacity: 0;
    z-index: 2;
  }

  input[type="checkbox"]:checked + label:after {
    content: "X";
    position: absolute;
    top: 0;
    bottom: unset;
    left: 0;
    width: 100%;
    color: $light-blue;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 800;
  }

  input + label {
    height: 30px;
    position: absolute;
    background: #17234d;
    border: 1px solid $light-blue;
    width: 30px;
    z-index: 0;
    font-size: 30px;
    overflow: hidden;
    line-height: 1;
  }
}
</style>


<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "CheckboxQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  data() {
    return {
      selectedAnswer: []
    }
  },
};
</script>
