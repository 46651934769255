export default {
    setConnected(state) {
        state.isConnected = true;
        localStorage.setItem('isConnected', JSON.stringify(state.isConnected));
    },
    setDisconnected(state){
        state.isConnected = false;
        localStorage.setItem('isConnected', JSON.stringify(state.isConnected));
    },
    setCsrf(state) {
        state.hasCsrf = true;
        localStorage.setItem('csrfToken', JSON.stringify(state.hasCsrf));
    },
};
