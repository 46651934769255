<template>
  <GameScreen>
    <div class="screen-title">
      Références {{ totalWon }}/{{ total }}
      <span></span>
    </div>
    <button id="close_button" class="button round">
      <span class="innerborder" @click.prevent="backToMenu">X</span>
      <span class="circle "></span>
    </button>
    <div class="inner-content full">
      <div class="wrapper justify-content-between">
        <div class="d-flex inner-wrapper pt-2 pt-lg-0 px-5">
          <div class="col-1 d-flex">
            <button class="scroll-buttons arrow round my-auto" v-if="pagination.page > 0" @click.prevent="doPrevious">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/></svg>
              </span>

              <span class="circle"></span>
            </button>
          </div>
          <div class="col-10 d-flex flex-nowrap align-items-center  ">


            <div class="grid-container w-auto flex-grow-1 px-lg-5">
              <Achievement
                  v-for="achievement in displayedAchievements"
                  :key="achievement.id"
                  :achievement="achievement"
                  v-on:ref-selected="validateChoice">
              </Achievement>
            </div>
          </div>
          <div class="col-1 d-flex">
            <button class="scroll-buttons arrow round right my-auto" v-if="pagination.page < pagination.maxPage - 1"
                    @click.prevent="doNext">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/>
</svg>
              </span>

              <span class="circle"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:character>
      <div v-if="referenceInteractive" id="advice-holder" class="d-flex flex-column position-absolute">
        <div class="bubble-text "><p>À quel univers est-ce que j'appartiens ?</p>
        </div>
        <img :src="characterImg" alt="" class="character-mini">
      </div>
    </template>
  </GameScreen>
</template>
<style scoped lang="scss">
.character-mini {
  max-height: 250px;
  transform: scaleX(-1);
}
</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapMutations, mapState} from "vuex";
import Achievement from "./Utils/Achievement.vue";
import {Screens} from "@/application/constants";

export default {
  name: "ReferenceScreen",
  components: {
    Achievement,
    GameScreen
  },
  data() {
    return {
      //totalWon: 0,
      total: 0,
    }
  },
  created() {
    this.getAchievements().catch((error) => {
      console.log(error)
      console.log('%c[ReferenceScreen] Error - loading default data', 'background: #222; color: #bada55')
      this.$store.commit('achievements/setAchievements', this.$store.state.achievements.mockAchievements)
    }).finally(() => {
      const reducer = (accumulator, item) => {
        if (item.won === 1) accumulator = parseInt(accumulator) + 1
        return accumulator
      };

      this.total = this.$store.state.achievements.achievements.length;
      //this.totalWon = this.$store.state.achievements.achievements.reduce(reducer, 0);

      this.initPagination().then(() => {
        this.setPage(0)
      })

    })
  },
  computed: {
    ...mapState('achievements', ['achievements', 'displayedAchievements', 'pagination']),
    ...mapState(['characterMini', 'referenceInteractive', 'referenceToGuess']),
    characterImg() {
      return `/static/vendors/images/npc/${this.characterMini.identifier}.png`
    },
    totalWon() {
      return this.achievements.filter(item => item.won === 1).length
    }
  },
  methods: {
    ...mapActions("achievements", ["getAchievements", "setPage", "initPagination", 'saveAchievement']),
    ...mapMutations(['setActiveScreen', 'clearReferenceToGuess']),
    ...mapMutations("achievements", ['activateAchievementsDisplayed']),
    doNext: function () {
      this.setPage(parseInt(this.pagination.page) + 1)
    },
    doPrevious: function () {
      this.setPage(this.pagination.page - 1)
    },
    backToMenu() {
      if (!this.referenceInteractive)
        this.setActiveScreen(Screens.TITLE)
      else
        this.setActiveScreen(Screens.GAME_MAP)
    },
    validateChoice(refId) {
      if (this.referenceInteractive) {
        if (this.referenceToGuess === refId) {
          this.activateAchievementsDisplayed(refId)
          this.saveAchievement(this.referenceToGuess)
          Game.updateRefCount()
          Game.disableInteractionReference(this.referenceToGuess)
        }
        setTimeout(() => {
          this.setActiveScreen(Screens.GAME_MAP)
          this.clearReferenceToGuess()
        }, 1500)
      }
    }
  }
}
</script>
