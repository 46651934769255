<template>


  <div class="inner-content">
    <div class="wrapper question-template true-false-container">
      <div class="screen-subtitle mx-auto" v-html="question.statement">
      </div>
      <div class="d-flex question-wrapper justify-content-cente flex-wrap">
        <button class="scroll-buttons arrow small up inactive d-none w-100 ">
          <arrow-svg/>
        </button>
        <form class="question-container ">
          <div class="d-flex flex-column position-relative scroll-question  justify-content-start">
            <div v-for="(answer, index) in answers" :key="answer.id" class="question-group d-flex justify-content-between">
              <div class=" col-9 col-lg-6 question flex-grow-1" style="color: white">
                {{ answer.text }}
              </div>
              <div class="col-3 d-flex ">
                <input :id="'true-'+index" class="col-6 true d-none" :name="'truefalse-'+index" type="radio" :value="{ id: answer.id, selected: true}" v-model="selectedAnswer[index]">
                <label :for="'true-'+index" class="justify-content-center d-flex"> Vrai </label>

                <input :id="'false-'+index" class="col-6 false d-none" :name="'truefalse-'+index" type="radio" :value="{ id: answer.id, selected: false}" v-model="selectedAnswer[index]">
                <label :for="'false-'+index" class="justify-content-center d-flex"> Faux </label>
              </div>
            </div>

              <div class="beveledbutton-wrapper submit-btn question-group button w-25 mx-auto" :class="{'disabled' : !hasEveryAnswers}">
                  <button  @click.prevent="doValidate" :disabled="!hasEveryAnswers">Valider</button>
              </div>

          </div>
        </form>

        <button class="scroll-buttons arrow small down d-none inactive  w-100 ">
          <arrow-svg/>
        </button>

      </div>
    </div>
  </div>
</template>
<style scoped>

  .true-false-container .question-group input[type="radio"] + label {
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    background: #88eeff;
    color: #88eeff;
    font-weight: 900;
    max-height: 50px;
  }

  .true-false-container .question-group input[type="radio"] + label::before {
     content: "";
     position: absolute;
     height: 100%;
     width: 80%;
   }

  .true-false-container input[type="radio"]:first-of-type + label::before{
    position: absolute;
    width: calc(100% - 2px);
    left: 1px;
    top: 1px;
    height: calc(100% - 2px);
    background: #17234d;
    z-index: -1;
  }
  .true-false-container input[type="radio"]:last-of-type + label::before{
    position: absolute;
    width: calc(100% - 2px);
    left: 1px;
    top: 1px;
    height: calc(100% - 2px);
    background: #17234d;
    z-index: -1;
  }
  .true-false-container input[type="radio"]:last-of-type + label {
    clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 86% 100%, 20% 100%, 0 100%, 0 0);
    z-index: 1;
  }
  .true-false-container input[type="radio"]:last-of-type + label::before {
     clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 80%, 86% 100%, 20% 100%, 0 100%, 0 0);
   }
  .true-false-container input[type="radio"]:first-of-type + label {
    clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 14% 100%, 0% 80%, 0% 20%);
    z-index: 1;
  }
  .true-false-container input[type="radio"]:first-of-type + label::before {
     clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 100%, 80% 100%, 14% 100%, 0% 80%, 0% 20%);
  }

  .true-false-container input[type="radio"]:checked + label {
    background: white;
    color: white;
  }
  .true-false-container input[type="radio"]:checked + label::before {
     width: calc(100% - 6px);
     height: calc(100% - 6px);
     left: 3px;
     top: 3px;
     background: rgb(58, 212, 240);
     background: linear-gradient(180deg, rgba(58, 212, 240, 1) 0%, rgba(17, 98, 213, 1) 100%);
  }

</style>
<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "TrueFalseQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  watch: {
    selectedAnswer: function (newValue) {
      const found = newValue.find(element => element === -1);
      this.hasEveryAnswers = !found;
    }
  },
  data() {
    return {
      answers: null,
      selectedAnswer: [],
      hasEveryAnswers: false
    }
  },
  created() {
    this.answers = this.question.answers
        .map((a) => ({sort: Math.random(), value: a}))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value)

    this.question.answers.map((item, index) => this.selectedAnswer[index] = -1)
  },
  methods: {
    doValidate: function () {
      this.selectedAnswer = this.selectedAnswer.filter(a => a.selected === true).map(a => a.id)
      this.validate();
    }

  }
};
</script>