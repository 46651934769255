<template>
  <div :class="{'active': this.achievement.won === 1, 'error': this.lose }" class="item beveledbutton-wrapper">
    <span v-if="achievement.icon && this.achievement.won === 1" class="character-wrapper">
      <img :src="`/static/vendors/images/refs/${achievement.icon}`"/>
    </span>
    <button @click="validate">{{ achievement.title }}</button>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {Screens} from "@/application/constants";

export default {
  name: "Achievement",
  data() {
    return {
      lose: false,
      clicked: false
    }
  },
  props: {
    achievement: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState(['referenceInteractive', 'referenceToGuess']),
  },
  methods: {
    validate() {
      if (this.referenceInteractive && !this.clicked) {
        this.clicked = true
        this.$emit('ref-selected', this.achievement.id)
        if (this.referenceToGuess !== this.achievement.id) {
          this.lose = true
        }
      }
    }
  }
}
</script>
