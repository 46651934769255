export default {
    setQuestions(state, data) {
        state.questions = data;
    },
    setCurrentQuestion(state, data) {
        state.currentQuestion = data;
    },
    setCurrentQuestionFromIdentifier(state, identifier) {
        state.currentQuestion = state.questions.find(q => q.identifier === identifier);
    },
    clearCurrentQuestion(state) {
        state.currentQuestion = null
    },
    addQuestionDone(state, id) {
        state.questionDone.indexOf(id) === -1 ? state.questionDone.push(id) : console.log("This item already exists");

    },
    addQuestionCounted(state, id) {
        if(state.questionCounted.indexOf(id) === -1)
            state.questionCounted.push(id)
    },
    clearQuestionDone(state) {
        state.questionDone = []
    },
    clearQuestionCounted(state) {
        state.questionCounted = []
    },
};