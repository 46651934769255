import axios from "axios";

export default {
    getCsrfToken({state, commit}) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/account/set_csrf/`).then((response) => {
                    commit("setCsrf");
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    checkAuthentication({state, commit}) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/account/check_auth/`).then((response) => {
                    if (response.data.authenticated) {
                        // console.log('authenticated')
                        commit("setConnected");
                        resolve(response.data);

                    } else {
                        // console.log('not authenticated')
                        commit("setDisconnected");
                        reject('not authenticated');

                    }
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    login({state, commit}, user) {
        return new Promise((resolve, reject) => {
            try {
                axios.post(`/account/login/`, user).then((response) => {
                    commit("setConnected");
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    register({state, commit}, user) {
        return new Promise((resolve, reject) => {
            try {
                var formData = new FormData();
                for (const [key, value] of Object.entries(user)) {
                    if (value) {
                        console.log(value)
                        formData.append(key, value)
                    }
                }

                axios.post('/account/register/', formData).then((response) => {
                    if (!response.data.message || response.data.message != 'fail') {
                        resolve(response);
                    } else {
                        reject(response.data);
                    }

                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },


};
