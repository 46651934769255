<template>
  <GameScreen>
    <div class="screen-title">
      Aide de jeu
      <span></span>
    </div>
    <button id="close_button" class="button round">
      <span class="innerborder" @click.prevent="backToMenu">X</span>
      <span class="circle "></span>
    </button>
    <div id="courselist" class="inner-content full d-flex">
      <div class="wrapper full align-items-center px-3 ">
        <div class="inner-wrapper d-flex w-100 pt-4 px-5">
          <div class="col-10 offset-1 help-wrapper">
            <div class="screen-subtitle mx-lg-auto mb-4 ">
              Quelques explications...
            </div>
            <div class="text-white">
              <p class="question-group  "> <span class="text-uppercase light-blue-text fw-bold">Déplacements :</span> Clique ou appuie sur l’écran pour faire déplacer le personnage à l’endroit voulu. Dans
                certains chapitres, cliquer sur les cases vertes te fait entrer ou sortir d’une pièce.</p>
              <div class="question-group">
                  <span class="text-uppercase light-blue-text fw-bold">Questions :</span>
               <ul>
                <li class="light-blue-text"><p>Clique sur la petite antenne à proximité d’un personnage ou élément de décor pour accéder à
                une question. Il suffit alors d’y répondre en cliquant sur la ou les bonnes réponses. Ton choix est
                directement suivi d’un feedback qui se permet de savoir si ta réponse était juste ou non. Si la petite
                  antenne est bleue, c’est que tu as déjà visité cette question.</p>
                </li>
              <li> <p>Le nombre de questions visitées apparaît en bas de l’écran. Le score calculé sur base de tes dernières
                réponses aux questions est également visible, cependant ce n’est pas lui qui détermine le diagnostic car
                on tient compte du nombre de tentatives et des thématiques des questions.</p> </li>
              </ul>
              </div>

              <div class="question-group">
                <span class="text-uppercase light-blue-text fw-bold">Références :</span>
                  <ul>
                    <li><p>Sélectionne le drapeau à côté d’un personnage ou d’un élément du décor. Cela amène à
                l’écran des références où il te faudra cliquer sur l’œuvre originale dans laquelle figure la personne ou
                      l’élément en question.</p></li>
                    <li><p>Le nombre de références trouvées apparaît en bas de l’écran.</p></li>
                  </ul>
              </div>

              <p class="question-group"> <span class="text-uppercase light-blue-text fw-bold">Astuce :</span> Après avoir répondu à une question, le diagnostic se met à jour automatiquement, alors n’hésite pas à
                retourner le consulter. Il t’indique les liens vers les outils de remédiation disponibles sur le Moodle
                de l’UCLouvain.</p>
              <h3 class="text-center fw-bolder  light-blue-text mb-5">Maintenant, c'est à toi de jouer !</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </GameScreen>
</template>
<style lang="scss" scoped>
#web-interface .screen-subtitle {
  width: 100%;
}

@media screen and (min-width: 992px) {
  #web-interface .screen-subtitle {
    width: unset;
  }
}
</style>

<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapMutations, mapState} from "vuex";
import {Screens} from "@/application/constants";

export default {
  name: "HelpScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      loading: true,
      courseIndex: 0,
    }
  },
  created() {
  },
  computed: {
    ...mapState(['lastScreen'])
  },
  methods: {
    ...mapMutations(['setActiveScreen']),
    backToMenu() {
      this.setActiveScreen(this.lastScreen)
    },
  }
}
</script>
