export default {
    setAchievements(state, data) {
        state.achievements = data;
    },
    setPaginationPage(state, page){
        state.pagination.page = page;
    },
    setPaginationTotal(state, total){
        state.pagination.total = total;
    },
    setPaginationMaxPage(state, maxPage){
        state.pagination.maxPage = maxPage;
    },
    setAchievementsDisplayed(state, arrAchievements){
        state.displayedAchievements = arrAchievements;
    },
    activateAchievementsDisplayed(state, id){
        let displayed = state.displayedAchievements
        displayed.map(item => {
            if(item.id === id) item.won = 1
        })
        state.displayedAchievements = displayed;
    },
    eraseAchievements(state, data){
        state.achievements = []
    }
};
