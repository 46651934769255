export default () => ({
    achievements: [],
    displayedAchievements: [],
    pagination: {
        itemPerPage: screen.width <= 760 ? 9 : 15,
        page: 0,
        total: 0,
        maxPage: 0,
    },
    mockAchievements: [
        {
            id: 1,
            title: "Stargate",
            icon: 'ref-stargate.png'
        },
        {
            id: 2,
            title: "Star Wars",
            icon: 'ref-starwars.png',
            won: true
        },
        {
            id: 3,
            title: "Star Trek",
            won: false
        },
        {
            id: 4,
            title: "Battlestar Galactica ",
            won: true
        },
        {
            id: 5,
            title: "Dune"
        },
        {
            id: 6,
            title: "X-files",
        },
        {
            id: 7,
            title: "VueJS"
        },
        {
            id: 8,
            title: "Mass Effect"
        },
        {
            id: 9,
            title: "Portal",
        },
        {
            id: 10,
            title: "Half Life",
            won: true
        },
        {
            id: 11,
            title: "Avatar",
            icon: 'ref-avatar.png',
            won: true
        },
        {
            id: 12,
            title: "Stargate2",
            icon: 'ref-stargate.png'
        },
        {
            id: 13,
            title: "Star Wars2",
            icon: 'ref-starwars.png',
            won: true
        },
        {
            id: 14,
            title: "Star Trek 2",
            won: false
        },
        {
            id: 15,
            title: "Battlestar Galactica 2",
            won: true
        },
        {
            id: 16,
            title: "Dune 2"
        },
        {
            id: 17,
            title: "X-files 2",
        },
        {
            id: 18,
            title: "VueJS 2"
        },
        {
            id: 19,
            title: "Mass Effect 2"
        },
        {
            id: 20,
            title: "Portal 2",
        },
        {
            id: 21,
            title: "Half Life 2",
            won: true
        },
        {
            id: 22,
            title: "Avatar 2",
            icon: 'ref-avatar.png',
            won: true
        }
    ]
});
