<template>
  <div>
    <GameScreen v-if="!showInstructions">
      <div class="screen-title">
        Choix de l'avatar
        <span></span>
      </div>
      <div id="avatar" class="inner-content full sky-background">
        <div class="wrapper align-items-center justify-content-between">

          <div class="screen-subtitle mx-auto">
            Choisis ton personnage <!--et ton pseudo-->
          </div>
          <div class=" d-flex justify-content-center align-items-center flex-grow-1">
            <button class="arrow round" @click.prevent="prevAvatar">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/></svg>
              </span>
              <span class="circle"></span>
            </button>

            <div>
              <div class="avatar-wrapper">
                <div v-show="imgReady" class="h-100 text-center">
                  <img :src="avatarImg" @load="imgLoaded">
                </div>
                <div v-show="!imgReady" class="loader"
                     style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
                  <div class="lds-dual-ring"></div>
                </div>
              </div>

              <!--            <input class="mt-auto mb-3 mx-auto d-block" placeholder="Pseudo" maxlength="12">-->
              <div class="beveledbutton-wrapper submit-btn mx-auto">
                <button @click.prevent="validate">Valider</button>
              </div>
            </div>

            <button class="scroll-buttons arrow round right m-auto" @click.prevent="nextAvatar">
              <span class="innerborder">
                <svg id="Calque_1" style="enable-background:new 0 0 111.6 136.2;" version="1.1"
                     viewBox="0 0 111.6 136.2" x="0px" xml:space="preserve"
                     xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     y="0px">
<path class="st0" d="M81.3,132.9L8.6,84.3c-11.5-7.7-11.5-24.6,0-32.4L81.3,3.3c12.9-8.7,30.3,0.6,30.3,16.2v97.2
C111.6,132.2,94.2,141.5,81.3,132.9z"/>
</svg>
              </span>

              <span class="circle"></span>
            </button>
          </div>
        </div>

      </div>
    </GameScreen>

    <div v-if="showInstructions" class="feedback-screen starship">
      <div class="character-container">
        <img src="/static/vendors/images/npc/plock.png" alt="">
        <div class="nametag">
          M. Plock
        </div>
      </div>
      <div class="speech-container">
        <div class="speech-bubble-big">
<!--          <div class="close-btn" @click="close">X</div>-->
          <div class="dialogue-content">
            <p>
              L'équipage est heureux de voyager sous vos ordres. N'oubliez pas de vous munir d'un carnet de notes, une
              calculatrice, un tableau périodique et de quoi écrire.
            </p>
          </div>
                    <div class="dialogue-pagination">
          <!--            <div :class="{'page-disabled': pageIndex === 0}" @click.prevent="prevSentence" class="page-btn">-->
          <!--              &lt;-->
          <!--            </div>-->
          <!--            <div v-if="displayRetry" @click.prevent="retryInteraction" class="page-btn retry-btn">Réessayer</div>-->
          <!--            <div v-if="displayRetry" @click.prevent="continueInteraction" class="page-btn retry-btn">{{-->
          <!--                continueOrQuit-->
          <!--              }}-->
          <!--            </div>-->
                      <div  @click.prevent="close" class="page-btn">&gt;</div>
                    </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.wrapper {
  padding: 10px calc(10% + 11px) 10px;
}

@media screen and (min-width: 992px) {
  .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
}

input {
  text-align: center;
}

#web-interface .beveledbutton-wrapper.submit-btn:not(.disabled):hover button {
  left: 4px;
}

.loader span {
  color: #a8e3f6;
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Anton", sans-serif !important;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.feedback-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  &.starship {
    background-image: url("@/../vendors/images/bg/starship.jpg");
  }

  display: flex;
  flex-direction: row;


  .character-container {
    width: 40%;

    .nametag {
      position: absolute;
      bottom: 5vh;
      left: 5vw;
      background-color: #2d9bf3;
      border: 1vh solid black;
      text-transform: uppercase;
      color: white;
      font-family: 'Anton', sans-serif;
      font-size: 5vh;
      padding: 0 50px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 10vw;
      width: 25%;
    }
  }

  .speech-container {
    width: 60%;

    .speech-bubble-big {
      position: absolute;
      top: 8vh;
      right: 3vw;
      background-image: url("@/../vendors/images/bubble-text-big.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 50vw;
      height: calc(50vw * (688 / 964));

      padding: 7% 2%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-around;

      .dialogue-content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: center;
          font-size: 1.5vw;
        }
      }

      .close-btn {
        position: absolute;
        top: 1.5vw;
        right: 1.5vw;
        width: 3.5vw;
        height: 3.5vw;

        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 3vw;
        line-height: 3.2vw;

        cursor: pointer;
      }
        .dialogue-pagination {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      }

      .page-btn {
        margin: 5px;
        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 2vw;

        cursor: pointer;
        padding: 0 15px;
        display: flex;
        align-items: center;
      }

    }
  }
}


</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapMutations} from "vuex";
import {Screens} from "@/application/constants";

export default {
  name: "EpisodeScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      avatarIndex: 0,
      imgReady: false,
      timeoutHandler: null,
      showInstructions: false,
    }
  },
  computed: {
    avatarImg() {
      let name = (Math.abs(this.avatarIndex) % 10 + 1).toString().padStart(2, '0')
      return `/static/vendors/images/ui/selection/${name}.png`
    }
  },
  methods: {
    ...mapMutations(['setSelectedAvatarIndex', "setActiveScreen"]),
    validate() {
      this.setSelectedAvatarIndex(Math.abs(this.avatarIndex) % 10 + 1)
      this.showInstructions = true;
      // this.setActiveScreen(Screens.EPISODES_LIST)
    },
    nextAvatar() {
      this.setTimer()
      this.avatarIndex++
    },
    prevAvatar() {
      this.setTimer()
      this.avatarIndex--
    },
    imgLoaded() {
      this.cancelTimer()
      this.imgReady = true
    },
    setTimer() {
      this.cancelTimer()
      this.timeoutHandler = setTimeout(() => this.imgReady = false, 100)
    },
    cancelTimer() {
      if (this.timeoutHandler !== null) {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = null
      }
    },
    close() {
      this.setActiveScreen(Screens.EPISODES_LIST)
    },
  }
}
</script>