import {Graphics, Container} from 'pixi.js'
import store from "~/store/store";
import {Screens} from "~/application/constants";
import {SoundManager} from "@/application/sounds";

export class Reference extends Container {
    buttonsCounts = 1
    buttonSize = 45 //todo resize button according to screen size

    upperLayer = null

    constructor(refData) {
        super();
        this.data = refData

        this.speed = 10
        this.gridCoords = {x: 0, y: 0}


        this.referenceId = this.data.reference.id


        this.upperLayer = new PIXI.Container()
        this.setupSprite()
        this.setupButtons()

        // Draw sphere on origin
/*        this.graphics = new Graphics()
        this.graphics.lineStyle(0, 0xffffff); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
        this.graphics.beginFill(0xFF0000, 1);
        this.graphics.drawCircle(0, 0, 10);
        this.graphics.endFill();
        this.addChild(this.graphics)*/

    }

    setupSprite() {
        this.sprite = new PIXI.Sprite(PIXI.Loader.shared.resources[this.data.reference.identifier].texture)
        this.sprite.anchor.set(0, 1)
        this.addChild(this.sprite)
    }

    setupButtons() {
        this.buttons = []
        this.buttonsContainer = new PIXI.Container()

        let referenceButton = new PIXI.Sprite(PIXI.Loader.shared.resources['images/ui/menu-references.jpg'].texture)
        referenceButton.interactive = true
        referenceButton.buttonMode = true;
        referenceButton.on('pointerup', () => {
            SoundManager.playSound('snd_quiz')
            store.commit('setCharacterMini', this.data.reference.identifier)
            store.commit('setReferenceToGuess', this.data.reference.id)
        })

        this.buttons.push(referenceButton)
        this.buttonsContainer.addChild(referenceButton)

        this.buttonsContainer.x = this.sprite.width  + 10
        this.buttonsContainer.y = -this.sprite.height

        this.upperLayer.addChild(this.buttonsContainer)
    }

    changeReferenceColor() {
        this.buttons[0].texture = PIXI.Loader.shared.resources['images/ui/menu-references-out.jpg'].texture
    }
}