<template>
  <div id="web-interface">
    <div id="videoContainer">
      <button id="close_button" class="button round">
        <span class="innerborder" @click.prevent="skipVideo">&gt;&gt;</span>
        <span class="circle "></span>
      </button>
      <video @ended="skipVideo" id="video" preload="auto" autoplay playsinline>
        <source :src="videoToPlay" type="video/mp4">
      </video>
      <div id="videoControls"></div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {Screens} from "~/application/constants";

export default {
  name: "VideoScreen",
  computed: {
    ...mapState(['nextScreen', 'videoToPlay'])
  },
  methods: {
    ...mapMutations(['setActiveScreen']),
    skipVideo() {
      SoundManager.resumeAmbiance()
      this.setActiveScreen(this.nextScreen)
    },
  }
}
</script>

<style scoped lang="scss">
#videoContainer {
  width: 100vw;
  height: 100vh;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }
}
</style>