export const Screens = Object.freeze({
    TITLE: 'title',
    AVATAR_SELECT: 'avatar select',
    EPISODES_LIST: 'episodes list',
    GAME_MAP: 'game map',
    CHARACTER_SPEAKING: 'character speaking',
    QUESTION: 'question',
    RESULTS: 'results',
    REFERENCES: 'references',
    LOGIN: 'login',
    REGISTER: 'register',
    VIDEO: 'video',
    HELP: 'help',
})

export const SoundAssets = Object.freeze({
    snd_click: '/static/vendors/assets/sounds/click.mp3',
    snd_croix: '/static/vendors/assets/sounds/croix.mp3',
    snd_ep_choice: '/static/vendors/assets/sounds/ep_choice.mp3',
    snd_excmark: '/static/vendors/assets/sounds/excmark.mp3',
    snd_main: '/static/vendors/assets/sounds/main.mp3',
    snd_parabole: '/static/vendors/assets/sounds/parabole.mp3',
    snd_quiz: '/static/vendors/assets/sounds/quiz.mp3',
    ambiant_mars: '/static/vendors/assets/sounds/ambiant_mars.mp3',
    ambiant_spaceship: '/static/vendors/assets/sounds/ambiant_spaceship.mp3',
    main_theme: '/static/vendors/assets/sounds/music.{mp3,ogg}',

    woman_default: '/static/vendors/assets/sounds/voices/woman-stressed.mp3',
    man_default: '/static/vendors/assets/sounds/voices/man-default.mp3',
    robot_default: '/static/vendors/assets/sounds/voices/robot-default.mp3',
})

export const VideoAssets = Object.freeze({
    ph_intro: '/static/vendors/assets/vid/intro-01.mp4',
    ph_outro: '/static/vendors/assets/vid/fin-01.mp4',
    ch_intro: '/static/vendors/assets/vid/intro-02.mp4',
    ch_outro: '/static/vendors/assets/vid/fin-02.mp4',
    bio_intro: '/static/vendors/assets/vid/intro-03.mp4',
    bio_outro: '/static/vendors/assets/vid/fin-03.mp4',
})

export const ReferenceAssets = Object.freeze({
    apes: '/static/vendors/images/refs/maps/apes.png',
    d9: '/static/vendors/images/refs/maps/d9.png',
    doom: '/static/vendors/images/refs/maps/doom.png',
    fifth: '/static/vendors/images/refs/maps/fifth.png',
    mib: '/static/vendors/images/refs/maps/mib.png',
    pokemon: '/static/vendors/images/refs/maps/pokemon.png',
    terminator: '/static/vendors/images/refs/maps/terminator.png',
    totalrecall: '/static/vendors/images/refs/maps/totalrecall.png',
    tron: '/static/vendors/images/refs/maps/tron.png',
    xfiles: '/static/vendors/images/refs/maps/xfiles.png',
})

export const PlayerAssets = Object.freeze({
    sel_01: '/static/vendors/images/ui/selection/01.png',
    sel_02: '/static/vendors/images/ui/selection/02.png',
    sel_03: '/static/vendors/images/ui/selection/03.png',
    sel_04: '/static/vendors/images/ui/selection/04.png',
    sel_05: '/static/vendors/images/ui/selection/05.png',
    sel_06: '/static/vendors/images/ui/selection/06.png',
    sel_07: '/static/vendors/images/ui/selection/07.png',
    sel_08: '/static/vendors/images/ui/selection/08.png',
    sel_09: '/static/vendors/images/ui/selection/09.png',
    sel_10: '/static/vendors/images/ui/selection/10.png',
    ui_01: '/static/vendors/images/ui/player/01.png',
    ui_02: '/static/vendors/images/ui/player/02.png',
    ui_03: '/static/vendors/images/ui/player/03.png',
    ui_04: '/static/vendors/images/ui/player/04.png',
    ui_05: '/static/vendors/images/ui/player/05.png',
    ui_06: '/static/vendors/images/ui/player/06.png',
    ui_07: '/static/vendors/images/ui/player/07.png',
    ui_08: '/static/vendors/images/ui/player/08.png',
    ui_09: '/static/vendors/images/ui/player/09.png',
    ui_10: '/static/vendors/images/ui/player/10.png',

    walk_01: '/static/vendors/images/player/spritesheets/vanilla_marche_01.png',
    walk_02: '/static/vendors/images/player/spritesheets/vanilla_marche_02.png',
    walk_03: '/static/vendors/images/player/spritesheets/vanilla_marche_03.png',
    walk_04: '/static/vendors/images/player/spritesheets/vanilla_marche_04.png',
    walk_05: '/static/vendors/images/player/spritesheets/vanilla_marche_05.png',
    walk_06: '/static/vendors/images/player/spritesheets/vanilla_marche_06.png',
    walk_07: '/static/vendors/images/player/spritesheets/vanilla_marche_07.png',
    walk_08: '/static/vendors/images/player/spritesheets/vanilla_marche_08.png',
    walk_09: '/static/vendors/images/player/spritesheets/vanilla_marche_09.png',
    walk_10: '/static/vendors/images/player/spritesheets/vanilla_marche_10.png',

    idle_01: '/static/vendors/images/player/spritesheets/vanilla_boucle_01.png',
    idle_02: '/static/vendors/images/player/spritesheets/vanilla_boucle_02.png',
    idle_03: '/static/vendors/images/player/spritesheets/vanilla_boucle_03.png',
    idle_04: '/static/vendors/images/player/spritesheets/vanilla_boucle_04.png',
    idle_05: '/static/vendors/images/player/spritesheets/vanilla_boucle_05.png',
    idle_06: '/static/vendors/images/player/spritesheets/vanilla_boucle_06.png',
    idle_07: '/static/vendors/images/player/spritesheets/vanilla_boucle_07.png',
    idle_08: '/static/vendors/images/player/spritesheets/vanilla_boucle_08.png',
    idle_09: '/static/vendors/images/player/spritesheets/vanilla_boucle_09.png',
    idle_10: '/static/vendors/images/player/spritesheets/vanilla_boucle_10.png',

    walk_01_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_01.png',
    walk_02_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_02.png',
    walk_03_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_03.png',
    walk_04_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_04.png',
    walk_05_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_05.png',
    walk_06_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_06.png',
    walk_07_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_07.png',
    walk_08_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_08.png',
    walk_09_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_09.png',
    walk_10_mars: '/static/vendors/images/player/spritesheets/cosmonaute_marche_10.png',

    idle_01_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_01.png',
    idle_02_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_02.png',
    idle_03_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_03.png',
    idle_04_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_04.png',
    idle_05_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_05.png',
    idle_06_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_06.png',
    idle_07_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_07.png',
    idle_08_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_08.png',
    idle_09_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_09.png',
    idle_10_mars: '/static/vendors/images/player/spritesheets/cosmonaute_boucle_10.png',
})
