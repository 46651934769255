<template>
  <div class="feedback-screen" :class="bgClass">
    <div class="character-container">
      <img :src="characterImg" alt="">
      <div class="nametag">
        {{ character.name }}
      </div>
    </div>
    <div class="speech-container">
      <div class="speech-bubble-big">
        <div class="close-btn" @click="close">X</div>
        <div class="dialogue-content">
          <p v-html="displayedSentence"></p>

        </div>
        <div class="dialogue-pagination">
          <div :class="{'page-disabled': pageIndex === 0}" @click.prevent="prevSentence" class="page-btn">
            &lt;
          </div>
          <div v-if="displayRetry" @click.prevent="retryInteraction" class="page-btn retry-btn">Réessayer</div>
          <div v-if="displayRetry" @click.prevent="continueInteraction" class="page-btn retry-btn">{{
              continueOrQuit
            }}
          </div>
          <div v-if="!displayRetry" @click.prevent="nextSentence" class="page-btn">&gt;</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.feedback-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;

  &.starship {
    background-image: url("@/../vendors/images/bg/starship.jpg");
  }

  &.mars {
    background-image: url("@/../vendors/images/bg/mars.jpg");
  }

  &.lol2000 {
    background-image: url("@/../vendors/images/bg/lol2000.jpg");
  }

  display: flex;
  flex-direction: row;


  .character-container {
    width: 40%;

    .nametag {
      position: absolute;
      bottom: 5vh;
      left: 5vw;
      background-color: #2d9bf3;
      border: 1vh solid black;
      text-transform: uppercase;
      color: white;
      font-family: 'Anton', sans-serif;
      font-size: 5vh;
      padding: 0 50px;
    }

    img {
      position: absolute;
      bottom: 0;
      left: 10vw;
      width: 25%;
    }
  }

  .speech-container {
    width: 60%;

    .speech-bubble-big {
      position: absolute;
      top: 8vh;
      right: 3vw;
      background-image: url("@/../vendors/images/bubble-text-big.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 50vw;
      height: calc(50vw * (688 / 964));

      padding: 7% 2%;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: space-around;

      .dialogue-content {
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          text-align: center;
          font-size: 1.5vw;
        }
      }

      .close-btn {
        position: absolute;
        top: 1.5vw;
        right: 1.5vw;
        width: 3.5vw;
        height: 3.5vw;

        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 3vw;
        line-height: 3.2vw;

        cursor: pointer;
      }

      .dialogue-pagination {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: stretch;
      }

      .page-btn {
        margin: 5px;
        background-color: #2d9bf3;
        color: white;
        text-align: center;

        font-size: 2vw;

        cursor: pointer;
        padding: 0 15px;
        display: flex;
        align-items: center;


        &.page-disabled {
          background-color: #8f8f8f;
          cursor: default;
        }

        &.retry-btn {
          font-size: 1.5vw;
        }
      }
    }
  }
}
</style>
<script>

import {mapMutations, mapState} from "vuex";
import {Screens} from "~/application/constants";
import {SoundManager} from "@/application/sounds";

export default {
  name: "CharacterTalkScreen",
  //todo remove mockup data
  data() {
    return {
      // character: {identifier: "ava", name: "mockup"},
      // dialogue: "Économisez jusqu’à 37% avec les prix",
      // map: {name: "mars"},
      // dialogue: "Économisez jusqu’à 37% avec les prix réservés aux professionnels sur Amazon Business. Créez votre compte gratuit.|Peut être disponible à un prix inférieur auprès d'autres vendeurs, potentiellement sans livraison gratuite avec Prime.",
      pageIndex: 0,
    }
  },
  mounted() {
    this.playVoiceLine()
  },
  computed: {
    ...mapState(['dialogue', 'character', 'map', 'feedback_type', 'interaction']),
    characterImg() {
      return `/static/vendors/images/npc/${this.character.identifier}.png`
    },
    sentences() {
      return this.dialogue.split('|')
    },
    pages() {
      //console.log("pages:", this.sentences.length)
      return this.sentences.length
    },
    displayedSentence() {
      return this.sentences[this.pageIndex]
    },
    bgClass() {
      if (this.character.identifier === 'lol2000')
        return 'lol2000'
      else if (this.map.name.includes("mars"))
        return 'mars'
      else
        return 'starship'
    },
    displayRetry() {
      return this.pageIndex >= this.pages - 1 && this.feedback_type === 'feedback_neg'
    },
    continueOrQuit() {
      return this.interaction.next !== null ? "Continuer" : "Quitter"
    }
  },
  methods: {
    ...mapMutations(['setActiveScreen', 'setInteraction']),
    ...mapMutations("question", ['clearCurrentQuestion', 'setCurrentQuestion']),
    playVoiceLine() {
      switch (this.character.identifier) {
        case 'ava':
        case 'eternity':
        case 'replay':
          SoundManager.playSound('woman_default')
          break
        case 'blub':
        case 'emc2':
        case 'wlily':
        case 'lol2000':
          SoundManager.playSound('robot_default')
          break
        default:
          SoundManager.playSound('man_default')
      }
    },
    close() {
      this.clearCurrentQuestion()
      if (!Game.checkForEpisodeCompletion())
        this.setActiveScreen(Screens.GAME_MAP)
    },
    nextSentence() {
      //console.log(this.pageIndex, this.pages, this.pageIndex < this.pages - 1)
      if (this.pageIndex < this.pages - 1) {
        this.pageIndex++
      } else {
        this.closeDialoque()
      }
    },
    closeDialoque() {
      if (this.feedback_type === 'intro' || this.feedback_type === 'clue')
        this.setActiveScreen(Screens.GAME_MAP)
      else if (!Game.checkForEpisodeCompletion())
        this.setActiveScreen(Screens.GAME_MAP)
    },
    prevSentence() {
      if (this.pageIndex > 0)
        this.pageIndex--
    },
    continueInteraction() {
      if (this.interaction.next !== null) {
        let nextInteraction = this.$store.state.map
            .interactions.find(i => i.id === this.interaction.next.id)
        this.setInteraction(nextInteraction)
        this.setCurrentQuestion(nextInteraction.question)

        this.closeDialoque()
      } else {
        this.clearCurrentQuestion()
        this.closeDialoque()
      }
    },
    retryInteraction() {
      this.clearCurrentQuestion()
      this.setInteraction(this.interaction)
      this.setCurrentQuestion(this.interaction.question)
      this.setActiveScreen(Screens.GAME_MAP)
    }
  }
}
</script>