<template>
  <div id="web-interface">
    <slot name="header"></slot>
    <div class="interface d-flex justify-content-between">
      <span class="border-container">
        <span class="border-left">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <span class="border-main">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <span class="border-right">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </span>
      <div class="main align-items-center justify-content-center d-flex flex-column">

        <slot></slot>

        <span class="decoration-main">
          <span>
            <span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span></span>
            <span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          </span>
          <span></span>
          <span>
            <span></span>
            <span></span>
          </span>
          <span></span>
        </span>
      </div>

    </div>
    <slot name="character">

      <div v-if="question" id="advice-holder" class="d-flex flex-column position-absolute"
           :class="{ hidden:!showCharacter}">
        <button id="info_button" class="button round">
          <span class="innerborder" @click.prevent="backToIntro">?</span>
          <span class="circle "></span>
        </button>

        <div class="bubble-button" @click="toogleBubble" :class="{ hidden:showBubble}"> ! </div>
        <div class="bubble-text" @click="toogleBubble" :class="{ hidden:!showBubble}">

          <span> x</span>
          <p>Pour revenir à l'énoncé</p><a href="" @click.prevent="backToIntro">cliquez ici</a>
        </div>
        <img :src="characterImg" alt="" class="character-mini">
      </div>
    </slot>
  </div>
</template>
<style scoped lang="scss">
.character-mini {
  max-height: 250px;
  transform: scaleX(-1);
}

#advice-holder {
  .hideshowbutton {
    width: 45px;
    margin-left: auto;
    font-weight: 900;
    text-align: center;
    border-radius: 5px;
    color: #88eeff;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    background: radial-gradient(circle, #132b76 0%, #060911 95%);
    right: 90px;
    width: 45px;
    height: 45px;
    //height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    border: 1px solid #167ed0;

    span {
      margin-left: -10px;
      margin-top: 1px;
      animation: 1s ease-in-out alternate infinite slideleft;
      transition: ease-in-out all 0s;
      animation-delay: 1s;
      transform: rotate(0deg);
    }

    @media screen and (min-width: 992px) {
      display: none;
    }
  }

  &.hidden .hideshowbutton span {
    //transform: rotate(180deg);
  }
}

@keyframes slideleft {
  from {
    margin-left: -10px;
  }
  to {
    margin-left: 10px;
  }
}
</style>

<script>

import {mapMutations, mapState} from "vuex";
import {Screens} from "~/application/constants";

export default {
  name: 'GameScreen',
  props: ['question'],

  data() {
    return {
      showCharacter: false,
      showBubble: true
    }
  },
  created() {
  },
  computed: {
    ...mapState(['characterMini', "interaction"]),
    characterImg() {
      return `/static/vendors/images/npc/${this.characterMini.identifier}.png`
    },

  },
  methods: {
    ...mapMutations(['setActiveScreen', 'setDialogue']),
    backToIntro() {
      this.setDialogue({type: 'intro', data: this.interaction})
    },
    toogleCharacter() {
      this.showCharacter = !this.showCharacter;
    },
    toogleBubble() {
      this.showBubble = !this.showBubble;
    },
  },
  mounted() {

  }
}
</script>