<template>
  <div class="template-wrapper">
    <div class="inner-content">
      <div class="wrapper question-template select-container">
        <div ref="screensubtitle" class="screen-subtitle mx-auto">
        <p  v-html="question.statement"> </p>
          <div class="fold-button" @click="fold()">
            <span ></span>
          </div>
        </div>
        <div class="d-flex question-wrapper justify-content-center ">

          <div v-if="question.illustration"
               class="pt-3 img-container d-flex align-items-start justify-content-center">
            <img :src="question.illustration" alt="">
          </div>

          <div class="pt-1 flex-grow-1 max-container-select">
            <button class="scroll-buttons arrow small up inactive d-none w-100 ">
              <arrow-svg/>
            </button>
            <form class="question-container">
              <div class="d-flex flex-column position-relative scroll-question justify-content-start" id="question-wrapper">

                <div v-for="(reference, refIndex) in question.formatted_answers.references" :key="reference.id"
                     class="question-group d-flex">
                  <div class="question">
                    {{ reference.text }}
                  </div>
                  <div :class="{ 'active' : selects[refIndex].visible === true }" class="select">
                    <select :id="'reference-'+reference.id" v-model="selectedAnswer[refIndex]" class="border-0"
                            @click="setVisible(refIndex)">
                      <option disabled="disabled" selected="selected" value="-1">Sélectionnez une réponse</option>
                      <option v-for="answer in answers" :key="answer.id" :value="answer.id">{{ answer.option }}</option>
                    </select>
                    <label :for="'reference-'+reference.id"> </label>
                    <button @click.prevent></button>
                  </div>
                </div>


                <div class="beveledbutton-wrapper submit-btn question-group button w-25 mx-auto"
                     :class="{'disabled' : !hasEveryAnswers}">
                  <button :disabled="!hasEveryAnswers" @click.prevent="doValidate">Valider</button>
                </div>

              </div>
            </form>

            <button class="scroll-buttons arrow small down d-none inactive w-100 ">
              <arrow-svg/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#web-interface .main .wrapper.select-container .question-group {
  padding: 0;
}

#web-interface .main .wrapper.select-container .question-group .question {
  padding: 5px 20px;
}
.screen-subtitle{
  height: auto;
}
.select {
  display: flex;
  align-items: center;
  position: relative;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #081434db;
  width: 100%;
  margin: 0 0 0 auto;
}

#web-interface .select-container .select button {
  background-color: #167ed0;
  width: 50px;
  position: absolute;
  right: 0;
  height: 100%;
  text-align: center;
  color: white;
  order: 2;
}

#web-interface .select-container .select button:after {
  content: "V";
  position: relative;
  display: block;
  transform: rotate(0deg);
  transition: ease-in-out 0.3s transform;

}

#web-interface .select-container .select.active button:after {
  transform: rotate(180deg);

}

.max-container-select {
  max-width: 100%;
}
.select{
  width: 325px;
}
.question{
  width: 250px;
}
@media screen and (min-width: 992px) {
  .max-container-select {
    max-width: 65%;
    min-width: 475px;
  }
  .select{
    min-width: 325px;
    width: unset;
  }
  .question{
    width: auto;
  }
}

select {
  order: 1;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 10px;
  margin: 0;
  color: white;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}

option {
  padding-left: 5px;
  color: black;
}

.question-container {
  padding: 15px 0 15px 15px;
}

</style>

<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "SelectQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  watch: {
    selectedAnswer: function (newValue) {
      const found = newValue.find(element => element === -1);
      this.hasEveryAnswers = !found;
    },
    references: function () {
      this.selectedAnswer.length = 0
      this.selects.length = 0
      this.generateSelects()
    }
  },
  data() {
    return {
      selectedAnswer: [],
      selects: [],
      hasEveryAnswers: false,
    }
  },

  mounted() {
    this.$nextTick(function () {
      if (this.$refs.screensubtitle.textContent.length >= 250) {
        this.$refs.screensubtitle.classList.add("foldable-question");
      }
     /* let test=document.getElementsByClassName("question");
      test.forEach(function (element) {
        console.log(element);
        let test2= (element.innerText);
        console.log(test2.length);
        console.log(element.textContent.length);
      })*/
    })
  },

  computed: {
    answers() {
      return this.question.formatted_answers.select_options
          .map((a) => ({sort: Math.random(), value: a}))
          .sort((a, b) => a.sort - b.sort)
          .map((a) => a.value)
    },
    references(){
      return this.question.formatted_answers.references
    }
  },
  created() {
    this.generateSelects()
  },
  methods: {
    generateSelects() {
      this.references.map((item, index) => {
        this.selectedAnswer[index] = -1;
        this.selects[index] = {
          visible: false
        }
      })
    },
    doValidate: function () {
      this.validate()
    },
    setVisible: function (index) {
      var item = this.selects[index];
      item.visible = !item.visible;
      this.selects.splice(index, 1, item)
    },
    fold :function () {
      this.$refs.screensubtitle.classList.toggle("unfolded");
  },
}
}
</script>
