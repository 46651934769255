<template>
  <GameScreen>
    <div class="inner-content no-title full sky-background">
      <div class="wrapper align-items-center justify-content-between">

        <div class="screen-subtitle mx-auto">
          Se connecter
        </div>

        <div class="inner-wrapper d-flex w-50 py-2 py-lg-5 flex-column align-items-center">
          <form class="mb-2 pt-lg-5">
            <div class="mb-4">
              <label for="username"> Nom d'utilisateur :</label><br/>
              <input id="username" class="w-100" v-model="username" name="username" type="text"/>
            </div>

            <div class="mb-4">
              <label for="password">Mot de passe :</label><br/>
              <input id="password" class="w-100" v-model="password" name="password" type="password"/>
            </div>

            <div v-if="message.length > 0" class="error text-danger text-center mb-4 ">
              {{ message }}
            </div>

            <div class="spinner-border d-block mx-auto mb-4" role="status" v-if="loading">
              <span class="sr-only">Chargement...</span>
            </div>


            <div class="beveledbutton-wrapper submit-btn  mb-2  mx-auto">

              <button @click.prevent="doLogin" :disabled="loading">Connexion</button>
            </div>

          </form>
          <a href="#" @click.prevent="register">Créer un compte</a>
          <a href="/accounts/password/reset/" target="_blank">Mot de passe oublié</a>
          <a href="#" @click.prevent="titlePage">Accueil</a>

        </div>
      </div>
    </div>
  </GameScreen>
</template>
<style lang="scss" scoped>
#web-interface .wrapper {
  padding: 10px calc(10% + 11px) 10px;
}
label {
  color: #88eeff;
  font-family: 'Anton', sans-serif;
  font-weight: 900;
  text-transform: uppercase;
}
input {
  background-color: #468eff4a;
  color: #88eeff;
}
.error {
  color: #ff6767
}
a {
  color: #88eeff;
}

@media screen and (min-width: 992px) {
  #web-interface .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
  .beveledbutton-wrapper {
    height: 40px;
  }
  #web-interface .main form {
    min-width: 250px;
  }
}


</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapMutations, mapState} from "vuex";
import {Screens} from "../../application/constants";

export default {
  name: "LoginScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      username: '',
      password: '',
      message: '',
      loading: false
    }
  },
  created() {

  },
  methods: {
    ...mapActions("account", ["login"]),
    ...mapMutations(['setActiveScreen']),

    titlePage(){
      this.setActiveScreen(Screens.TITLE)
    },
    doLogin() {
      this.loading = true;
      this.login({username: this.username, password: this.password}).then(() => {
        this.setActiveScreen(Screens.TITLE)
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message)
          this.message = error.response.data.message
      }).finally(() => {
        this.loading = false
      })
    },
    register() {
      this.setActiveScreen(Screens.REGISTER)
    }
  }
}
</script>
