<template>
  <div class="inner-content">
    <div class="wrapper question-template qcm-single">
      <div class="screen-subtitle mx-auto" v-html="question.statement">
      </div>
      <div class="d-flex question-wrapper justify-content-center row flex-nowrap">

        <div class="col-12 pt-2">
          <button class="scroll-buttons arrow small up inactive d-none">
            <arrow-svg/>
          </button>
          <form class="question-container">
            <div class="d-flex flex-column scroll-question  justify-content-start" id="question-wrapper" ref="questioncontainer">
              <button v-for="answer in question.answers" :key="answer.id" class="button question question-group"
                      @click.prevent="setModel(answer)" v-html="answer.text">
              </button>
            </div>
          </form>
          <button id="button_down" class="scroll-buttons arrow small down d-none">
            <arrow-svg/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import QuestionMixin from "@/components/screens/QuestionType/QuestionMixin.vue";
import ArrowSvg from "@/components/utils/ArrowSvg.vue";

export default {
  name: "McqQuestion",
  components: {ArrowSvg},
  mixins: [QuestionMixin],
  mounted() {
    this.$nextTick(function () {
      setTimeout(function(){
        let wrapper =document.getElementById("question-wrapper");
        let totalsize=0
                wrapper.children.forEach(function (element) {
                  totalsize = totalsize +element.offsetHeight +10;
                });
        if (totalsize >=wrapper.offsetHeight){
          document.getElementById("button_down").classList.remove("d-none");
        }
        },
              1000);


    })
  },
  methods: {
    setModel: function (answer) {
      this.selectedAnswer = answer
      this.validate()
    }
  }
};
</script>
