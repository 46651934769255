<template>
  <GameScreen>
    <div class="inner-content no-title full sky-background">
      <div class="loader" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center">
        <span class="stylise-font">Chargement&nbsp;</span>
       <div class="lds-dual-ring"></div>
      </div>
    </div>
  </GameScreen>
</template>
<style lang="scss" scoped>
#web-interface .wrapper {
  padding: 10px calc(10% + 11px) 10px;
}

@media screen and (min-width: 992px) {
  #web-interface .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
  .beveledbutton-wrapper {
    height: 40px;
  }
  label {
    color: #88eeff;
    font-weight: 900;
    text-transform: uppercase;
  }
  input {
    background-color: #468eff4a;
    color: #88eeff;
  }
}

.loader span {
  color: #a8e3f6;
  font-size: 62px;
  text-transform: uppercase;
  font-weight: bold;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #cef;
  border-color: #cef transparent #cef transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapMutations, mapState} from "vuex";
import {Screens} from "../../application/constants";

export default {
  name: "LoadingScreen",
  components: {
    GameScreen
  },
}
</script>
