<template>
  <GameScreen>
    <div class="inner-content no-title full sky-background">
      <div class="wrapper align-items-center justify-content-between">

        <div class="screen-subtitle mx-auto">
          S'enregistrer
        </div>
        <div class="inner-wrapper d-flex w-50 py-5 flex-column align-items-center">
          <form>
            <div class="mb-4">
              <label for="username">Nom d'utilisateur :</label><br/>
              <input id="username" class="w-100" v-model="username" name="username" type="text"/>
            </div>

            <div class="mb-4">
              <label for="email">Email :</label><br/>
              <input id="email" class="w-100" v-model="email" name="email" type="email"/>
            </div>

            <div class="mb-4">
              <label for="password1">Mot de passe :</label><br/>
              <input id="password1" class="w-100" v-model="password1" name="password1" type="password"/>
            </div>

            <div class="mb-4">
              <label for="password2">Confirmation du mot de passe :</label><br/>
              <input id="password2" class="w-100" v-model="password2" name="password2" type="password"/>
            </div>

            <div class="mb-4">
              <label for="faculty">Je vais étudier dans la Faculté</label><br/>
              <select id="faculty" class="w-100" v-model="faculty" name="faculty">
                <option value="" disabled>---</option>
                <option value="sciences">des sciences</option>
                <option value="bioengineer">des bioingénieurs</option>
                <option value="architecture">d’architecture</option>
                <option value="other">autre</option>
              </select>
            </div>

            <div v-if="message.length > 0" class="error text-danger text-center mb-4">
              {{ message }}
            </div>

            <div v-if="loading" class="spinner-border d-block mx-auto mb-4" role="status">
              <span class="sr-only">Chargement...</span>
            </div>

            <div class="beveledbutton-wrapper submit-btn  mx-auto mb-4">
              <button :disabled="loading" @click.prevent="doRegister">Créer un compte</button>
            </div>
          </form>

          <a href="#" @click.prevent="loginPage">Retour au login</a>
          <a href="#" @click.prevent="titlePage">Accueil</a>

        </div>
      </div>

    </div>
  </GameScreen>
</template>
<style lang="scss" scoped>
#web-interface .wrapper {
  padding: 10px calc(10% + 11px) 10px;
}

@media screen and (min-width: 992px) {
  #web-interface .wrapper {
    padding: 30px calc(10% + 11px) 10px;
  }
  .beveledbutton-wrapper {
    height: 40px;
  }
  #web-interface .main form {
    min-width: 250px;
  }
  label {
    color: #88eeff;
    font-family: 'Anton', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
  }
  input, select {
    background-color: #468eff4a;
    color: #88eeff;
  }
  select option {
    background-color: rgba(21, 69, 145, 1);
    color: #88eeff;
  }
}

a {
  color: #88eeff;
}
</style>
<script>
import GameScreen from "@/components/screens/GameScreen.vue"
import {mapActions, mapMutations, mapState} from "vuex";
import {Screens} from "../../application/constants";

export default {
  name: "LoginScreen",
  components: {
    GameScreen
  },
  data() {
    return {
      username: '',
      email: '',
      password1: '',
      password2: '',
      faculty: '',
      message: '',
      loading: false
    }
  },
  created() {

  },
  methods: {
    ...mapActions("account", ["register"]),
    ...mapMutations(['setActiveScreen']),
    ...mapMutations("account", ['setConnected']),
    loginPage() {
      this.setActiveScreen(Screens.LOGIN)
    },
    titlePage() {
      this.setActiveScreen(Screens.TITLE)
    },
    doRegister() {
      this.loading = true;
      this.register({
        username: this.username,
        email: this.email,
        password1: this.password1,
        password2: this.password2,
        faculty: this.faculty,
      }).then(() => {
        this.setConnected()
        this.setActiveScreen(Screens.TITLE)
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message
        }

        if (error && error.errors) {
          var errors = [];
          for (const [key, value] of Object.entries(error.errors)) {
            errors.push(`${key} : ${value}`)
          }

          this.message = errors.join(', ')
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
