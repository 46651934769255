import "./styles/index.scss"
import "bootstrap/dist/js/bootstrap.bundle"

import Vue from 'vue'
import App from './App.vue'
import store from './store/store'
import {Game} from './application/game'
import {SoundManager} from './application/sounds'


import axios from "axios";

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

let vueApp = new Vue({
    store, //not used
    beforeCreate() { this.$store.commit('initialiseStore');},
	render: h => h(App)
}).$mount('#app')

window.vueApp = vueApp
window.Game = Game
window.SoundManager = SoundManager





