import axios from "axios";

function getDisplayedResults(state, page) {
    var itemPerPage = state.pagination.itemPerPage;

    var startIndex = page * itemPerPage;
    var endIndex = page * itemPerPage + itemPerPage;

    if (page === state.pagination.maxPage - 1) {
        endIndex = state.results.length
    }

    return state.results.slice(startIndex, endIndex)
}

export default {
    setPage({state, commit}, pageNumber) {
        return new Promise((resolve, reject) => {
            if (pageNumber < 0 || pageNumber >= state.pagination.maxPage) {
                reject({message: 'Bad page number'});
            }

            var toDisplay = getDisplayedResults(state, pageNumber);

            commit("setPaginationPage", pageNumber);
            commit("setResultsDisplayed", toDisplay)

            resolve();
        });
    },
    initPagination({state, commit}) {
        return new Promise((resolve, reject) => {
            var total = state.results.length;
            var itemPerPage = state.pagination.itemPerPage;

            var maxPage = Math.trunc(total / itemPerPage);
            if (total % itemPerPage > 0) {
                maxPage += 1
            }

            commit("setPaginationTotal", total);
            commit("setPaginationMaxPage", maxPage);

            resolve();
        });
    },
    getResults({state, commit}) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/api/results/`).then((response) => {
                    commit("setResults", response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
    getResultsForEpisode({state, commit}, episodeId) {
        return new Promise((resolve, reject) => {
            try {
                axios.get(`/api/results/${episodeId}/`).then((response) => {
                    commit("setResults", response.data);
                    resolve(response.data);
                }).catch((error) => {
                    reject(error);
                })

            } catch (error) {
                reject(error);
            }
        });
    },
};
